import React, { useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Box, Button, Tab, Tabs } from '@mui/material';
import axios from 'axios';
import SftGlobalData from '../library/SftGlobalData';
import Colors from '../library/Colors';
import ListItemV2 from '../components/ListItemV2';
import FloatingAddButton from '../components/FloatingAddButton';
import FloatingSyncButton from '../components/FloatingSyncButton';
import facebookLogo from '../assets/module-icons/ic_facebook.png';
import googleLogo from '../assets/module-icons/ic_google.png';
import channableLogo from '../assets/module-icons/ic_channable.png';

const IntegrationScreen = (props) => {
    const [client, setClient] = useState(0);
    const [tabValue, setTabValue] = useState(0);
    const navigate = useNavigate();

    const integrations = [
        { id: 1, name: 'Facebook', integration: 'facebook', icon: facebookLogo },
        { id: 2, name: 'Google', integration: 'google', icon: googleLogo },
        { id: 3, name: 'Channable', integration: 'channable', icon: channableLogo },
    ];

    const TabPanel = (props) => {
        const { children, value, index, ...other } = props;
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <div style={{ paddingTop: 15 }}>{children}</div>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    const a11yProps = (index) => ({
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    });

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const submitHandler = async (list, value) => {
        try {
            const url = `${SftGlobalData.baseURL_API_old}?key=${props.api}&edit=1&integrationId=${list}&value=${value}`;
            const response = await axios.get(url);
            if (SftGlobalData.debug) {
                console.log('url', url);
                console.log('response', response);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const DataGrid = ({ integration, api }) => {
        const [pageSize, setPageSize] = useState(10);
        const [lists, setLists] = useState([]);
        const [db, setDb] = useState(0);
        const [loading, setLoading] = useState(true);

        const gridColumns = useMemo(() => [
            {
                field: 'id',
                headerName: props.t('id'),
                width: 50,
                align: "left",
            },
            {
                field: 'foldername',
                headerName: props.t('integration_folder'),
                headerClassName: 'bold',
                minWidth: 250,
                align: "left",
            },
            {
                field: 'store',
                headerName: props.t('integration_stores'),
                headerClassName: 'bold',
                width: 100,
                align: "left",
            },
            {
                field: 'url',
                headerName: props.t('integration_photo_URL'),
                headerClassName: 'bold',
                width: 375,
                align: "left",
            },
            {
                field: 'online',
                headerName: props.t('integration_online'),
                headerClassName: 'bold',
                width: 100,
                align: "left",
            },
            {
                field: 'active',
                headerName: props.t('active'),
                width: 100,
                align: "left",
                renderCell: (params) => (
                    <ListItemV2
                        key={params.row.id}
                        id={params.row.id}
                        lang={props.lang}
                        name={params.row.name}
                        config={params.row.sms_birthday_body}
                        active={params.row.active}
                        className="exclude-row-click"
                        api={props.api}
                        theme={props.theme}
                        submitHandler={(list, value) => submitHandler(list, value)}
                    />
                ),
            },
            {
                field: 'edit',
                headerName: props.t('edit'),
                minWidth: 60,
                maxWidth: 60,
                align: 'center',
                headerAlign: 'center',
                renderCell: (params) => (
                    <Button
                        style={{ color: props.theme === 'dark' ? Colors.dark_text : Colors.light_text, height: "100%", borderRadius: "50%" }}
                        onClick={() => {
                            const filteredList = lists.find(list => list.id === params.row.id);
                            navigate(`/${props.lang}/${props.integration}-detail/${params.row.id}`, { state: { list: filteredList, database: db } });
                        }}
                    >
                        <FontAwesomeIcon icon={faPencilAlt} color={Colors.def_orange} size="2x" />
                    </Button>
                ),
            },
        ]);

        const fetchIntegrationData = useCallback(async () => {
            setLoading(true);
            try {
                const url = `${SftGlobalData.baseURL_API}integrations&action=overview&type=${integration}`;
                const postData = { key: api };
                const response = await axios.post(url, postData, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                    },
                });
                console.log("response:", response);
                if (SftGlobalData.debug) {
                    console.log('url', url);
                    console.log('postData', postData);
                    console.log('response fetch', response);
                }
                setDb(response.data.db);
                setClient(response.data.client);
                setLists(response.data.lists ?? []);
                setLoading(false);
            } catch (e) {
                console.error(e);
                setLoading(false);
            }
        }, [integration, api]);

        useEffect(() => {
            props.changeScreen('Licence');
            fetchIntegrationData();
        }, [fetchIntegrationData]);


        const handleCellClick = (params, event) => {
            if (event.target.closest(".exclude-row-click")) {
                event.stopPropagation();
            }
        };
        const handleRowClick = (params, event) => {
            const filteredList = lists.find(list => list.id === params.row.id);
            navigate(`/${props.lang}/${integration}-detail/${params.row.id}`, { state: { list: filteredList, database: db } });
        };

        return (
            <div>
                <DataGridPro
                    className={props.theme}
                    columns={gridColumns}
                    rows={lists?.map((row) => ({
                        id: row.id,
                        foldername: row.config.foldername ?? props.db?.description,
                        store: row.config.stores ?? "",
                        url: row.config.url ?? "",
                        online: row.config.online,
                        active: row.active,
                        edit: "Edit",
                    }))}
                    rowHeight={38}
                    autoHeight
                    pagination
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[10, 20, 30]}
                    loading={loading}
                    onRowClick={handleRowClick}
                    onCellClick={handleCellClick}
                />
            </div>
        );
    }

    return (
        <div className={(props.screenWidth) < 600 ? 'not_expanded container_content _80perc ' + props.theme + ' container_content_' + props.theme : 'expanded container_content _80perc ' + props.theme + ' container_content_' + props.theme} >
            <Box className={props.theme + ' integrations'}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className={props.theme + ' container_tabs_below_subtitle'}>
                    <Tabs value={tabValue} onChange={handleChange} className={props.theme} variant="scrollable" scrollButtons="auto">
                        {integrations.map((integration, i) => (
                            <Tab key={integration.id} label={<div style={{ display: 'flex', alignItems: 'center' }}><img src={integration.icon} width="30px" style={{ marginRight: 10 }} alt={integration.name}/> {integration.name}</div>} {...a11yProps(i)} className={props.theme} />
                        ))}
                    </Tabs>
                </Box>

                {integrations.map((integration, i) => (
                    <TabPanel key={integration.id} value={tabValue} index={i}>
                        {client && <FloatingSyncButton disabled={false} url={`${SftGlobalData.baseURL_shopping}index.php?client=${client}`} />}
                        <FloatingAddButton onClick={() => navigate(`/${props.lang}/${integration.integration}-detail/+`)} />
                        <div className={`container_list__title ${props.theme}`}><h2>{integration.integration}</h2></div>
                        <DataGrid integration={integration.integration} api={props.api} />
                    </TabPanel>
                ))}
            </Box>
        </div>
    );
}

export default IntegrationScreen;
