import { Switch } from '@mui/material';
import React, { useEffect, useState } from 'react';


const InformationScreen = (props) =>  {  
    const [holiday, setHoliday] = useState(props.holidays);

    useEffect(() => {
        props.changeScreen('logging');
        
      }, [props.api]);

    //console.log("props", props);

    return (
        <div className={'_100perc log__main_container log__padding_sidebar'}>
            <div className={'log__container_content ' + props.theme + ' container_content_' + props.theme + ' box_fade-in margin_top_50px'} >
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center'}}>
                    
                    {
                        // --------------------------------------------------------------------
                        // TITLE / HEADER
                        // --------------------------------------------------------------------
                    }
                    <div className={props.theme + " _100perc "}>
                        <div className="container_list__title ">
                            <h2 className={props.theme} type="text" id="logging" name="logging">User information</h2>
                        </div>
                        <hr />
                    </div>
                </div>
                <div className='columnContainerCenter' style={{alignItems: 'center'}}>
                    <div className='_50perc rowContainerBetween width_max_500 margin_bottom_10px'>
                        <div className='bold'>Login:</div>
                        <div>{props.login.login}</div>
                    </div>
                    <div className='_50perc rowContainerBetween width_max_500 margin_bottom_10px'>
                        <div className='bold'>First name:</div>
                        <div>{props.login.first_name}</div>
                    </div>
                    <div className='_50perc rowContainerBetween width_max_500 margin_bottom_10px'>
                        <div className='bold'>Last name:</div>
                        <div>{props.login.last_name}</div>
                    </div>
                    <div className='_50perc rowContainerBetween width_max_500 margin_bottom_10px'>
                        <div className='bold'>SFT User ID:</div>
                        <div>{props.login.sft_user_id}</div>
                    </div>
                    <div className='_50perc rowContainerBetween width_max_500 margin_bottom_10px'>
                        <div className='bold'>SFT permissions:</div>
                        <div>{props.login.sft_group}</div>
                    </div>
                    <div className='_50perc rowContainerBetween width_max_500 margin_bottom_10px'>
                        <div className='bold'>SFT participation:</div>
                        <div>{props.login.sft_status === "1" ? "ALL" : props.login.sft_status === "2" ? "VLAANDEREN" : " WALLONIE"}</div>
                    </div>
                    <div className='_50perc rowContainerBetween width_max_500 margin_bottom_10px'>
                        <div className='bold'></div>
                        <div></div>
                    </div>
                    <div className='_50perc rowContainerBetween width_max_500 margin_bottom_10px'>
                        <div className='bold'>Holidays enabled?</div>
                        <div>
                            <Switch
                              checked={holiday}
                              onChange={()=>{setHoliday(!holiday); props.toggleHolidays();}}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </div>
                    </div>
                </div>
                

            </div>
        </div>
    );
}

export default InformationScreen;
