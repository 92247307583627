import '../../styles/main.css';
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import axios from 'axios';
import SftGlobalData from '../../library/SftGlobalData';

import { TailSpin } from  'react-loader-spinner';
import Colors from '../../library/Colors';

const ShippingPrint = (props) =>  {
    const [loading, setLoading] = useState(true);   
    const [fileUrl, setFileUrl] = useState('');
    

    /*
    let customer            = {firstname: "", lastname: "", company: "", street_name: "", house_number: "",zip: "", city: "",country: "",email: "", phone: "", language: ""};
    let shipper             = {name: "", company: "", street_name: "", house_number: "", zip: "", city: "", country: "", email: "", phone: ""};
    let shipment_type       = {shipper: "", provider: ""};
    let shipment_options    = {shipment_type: "", tracking_email: "", tracking_sms: "", saterday_delivery: "", second_delivery: "", sameday_delivery: "", evening_delivery: "", sameday_delivery: "", return_label: "", sameday_delivery: "", signed: "", basic_insurance: "", extra_insurance: "", weight: "", delivery_date: "", parcels: ""};*/

   
    useEffect(() => { 
        if(props.selectedPrintOption !== undefined){            
            let receiptNr = 0;
            switch (props.selectedPrintOption){
                case "email":
                    if(props.receiptNr === undefined || props.receiptNr === 0 || props.receiptNr === false){
                        receiptNr = "man_" + Math.floor(Math.random() * 1000000000);
                    } else {
                        if(typeof props.selectedStore === "undefined" || props.selectedStore === "" || props.selectedStore === false || props.selectedStore === "undefined")
                            receiptNr = props.receiptNr;
                        else
                            receiptNr = props.receiptNr + props.selectedStore;
                    }
                    createShipment(receiptNr, props.selectedPrintOption);
                    setLoading(false); 
                    break;
                case "shipping":
                    if(props.receiptNr === undefined || props.receiptNr === 0 || props.receiptNr === false){
                        receiptNr = "man_" + Math.floor(Math.random() * 1000000000);
                    } else {
                        if(typeof props.selectedStore === "undefined" || props.selectedStore === "" || props.selectedStore === false || props.selectedStore === "undefined")
                            receiptNr = props.receiptNr;
                        else
                            receiptNr = props.receiptNr + props.selectedStore;
                    }
                    createShipment(receiptNr, props.selectedPrintOption);
                    setLoading(false); 
                    break;
                case "print":
                    if(props.receiptNr === undefined || props.receiptNr === 0 || props.receiptNr === false){
                        receiptNr = "man_" + Math.floor(Math.random() * 1000000000);
                    } else {
                        if(typeof props.selectedStore === "undefined" || props.selectedStore === "" || props.selectedStore === false || props.selectedStore === "undefined")
                            receiptNr = props.receiptNr;
                        else
                            receiptNr = props.receiptNr + props.selectedStore;
                    }
                    createShipment(receiptNr, props.selectedPrintOption);                   
                    setLoading(false); 
                    break;
                default:
                    break;
            }
        }
    },[props.selectedPrintOption, props.receiptNr]);

    function createShipment(receiptNr, sendOption){ 
        let responseData = '';
        let url = SftGlobalData.baseURL_API + 'shipping&action=postOrder';
        let postData = {
        key:                        props.api,
        provider:                   props.shipmentType.shipper + "_" + props.shipmentType.provider,
        shipment_type:              props.shipmentOptions.shipment_type,
        shipping_name:              props.shipper.name,
        shipping_company:           props.shipper.company,
        shipping_street_name:       props.shipper.street_name,
        shipping_street_number:     props.shipper.house_number,
        shipping_zip:               props.shipper.zip,
        shipping_city:              props.shipper.city,
        shipping_country:           props.shipper.country,
        shipping_email:             props.shipper.email,
        shipping_phone:             props.shipper.phone,
        parcels:                    props.shipmentOptions.parcels,
        language:                   props.lang.toUpperCase(),
        first_name:                 props.customer.firstname,
        last_name:                  props.customer.lastname,
        email:                      props.customer.email,
        street:                     props.customer.street_name,
        street_number:              props.customer.house_number,
        street_number_addition:     props.customer.house_number_addition,
        zip:                        props.customer.zip,
        city:                       props.customer.city,
        country:                    props.customer.country,           
        tracking_email:             (props.shipmentOptions.tracking_email === "checked" ? true : false),
        second_delivery:            (props.shipmentOptions.second_delivery === "checked" ? true : false),
        sameday_delivery:           (props.shipmentOptions.sameday_delivery === "checked" ? true : false),
        evening_delivery:           (props.shipmentOptions.evening_delivery === "checked" ? true : false),
        basic_insurance:            (props.shipmentOptions.basic_insurance === "checked" ? true : false),
        return_label:               (props.shipmentOptions.return_label === "checked" ? true : false),
        extra_insurance:            props.shipmentOptions.extra_insurance,
        weight:                     props.shipmentOptions.weight,     
        parcelValue:                props.shipmentOptions.parcelValue,
        requested_delivery_date:    props.shipmentOptions.delivery_date,
        order_id:                   receiptNr };
        axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }
            }).then(response => {
                if(SftGlobalData.debug){
                    console.log('url', url);
                    console.log('data log', postData);
                    console.log('response', response);
                    
                }
                responseData = response.data;  
                if(SftGlobalData.debug){
                    console.log("sendOption: ", sendOption);
                }      
                    

                if(responseData["error"] !== undefined){    
                    props.setError(responseData["error"]);
                } else if(responseData["message"] !== undefined){  
                    props.setError(responseData["message"]);
                } else if(sendOption === "print" && responseData["status"] === "ok"){
                    props.setError('');
                    if(props.api !== ''){       //hier weg (begin)                      
                        let url = SftGlobalData.baseURL_API + 'shipping&action=fetchLabel';    
                        let postData = {
                            key:                        props.api,
                            provider:                   props.shipmentType.shipper + "_" + props.shipmentType.provider,                            
                            print_return_label:         (props.shipmentOptions.return_label === "checked" ? true : false),
                            order_id:                   receiptNr,
                            label_type:                 (typeof props.shipmentOptions?.paper_size !== "undefined" ? props.shipmentOptions.paper_size : "A6")
                            
                        };        
                        axios.post(url, postData, {
                                headers : {
                                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                                }
                            }).then(response => {
                                if(SftGlobalData.debug){
                                    console.log('url', url);
                                    console.log('data', postData);
                                    console.log("response", response);
                                }
                                responseData = response.data;                                         
                                setFileUrl(responseData["url"]);                                                                          
                            }
                        );            
                    }
                } else if(sendOption === "email" && responseData["status"] === "ok"){
                    props.setError('');
                    if(props.api !== ''){                           
                        let url = SftGlobalData.baseURL_API + 'shipping&action=mailLabel';            
                        axios.post(url, 
                            {
                                key:                        props.api,
                                provider:                   props.shipmentType.shipper + "_" + props.shipmentType.provider,                            
                                print_return_label:         (props.shipmentOptions.return_label === "checked" ? true : false),
                                order_id:                   receiptNr,
                                label_type:                 props.shipmentOptions.paper_size,
                                sendTo:                     "CHANGE THIS TO VARIABLE FIELD FROM KASVAR"
                                
                            }, {
                                headers : {
                                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                                }
                            }).then(response => {
                                if(SftGlobalData.debug){
                                    console.log('url', url);
                                    console.log('data', {
                                        key:                        props.api,
                                        provider:                   props.shipmentType.shipper + "_" + props.shipmentType.provider,                            
                                        print_return_label:         (props.shipmentOptions.return_label === "checked" ? true : false),
                                        order_id:                   receiptNr,
                                        label_type:                 props.shipmentOptions.paper_size,
                                        sendTo:                     "CHANGE THIS TO VARIABLE FIELD FROM KASVAR"
                                    });
                                } 
                                responseData = response.data;                                         
                                setFileUrl(responseData["url"]);                                                                         
                            }
                        );            
                    }
                }
            } 
        );             
    } 

    function showBackButton(){
        if(props.error !== ''){
            return <div>
                        {/*<Button 
                            variant="contained" 
                            className={'button_container__button width_140px align_left'} 
                            onClick={()=>{props.switchForm(false);
                            }}>{props.t('back')}</Button>
                        <h2 className={(props.theme==='dark') ? "dark container_list__title detail" : "light container_list__title detail"} 
                            type="text" 
                            id="list_name" 
                        name="listName">{props.t('titleShippingConfirmation')}</h2>*/}

                        {props.screenWidth >= 600 && <Button variant="contained" className={'button_container__button width_140px align_left'} onClick={()=>{props.switchForm(false);}}>{props.t('back')}</Button>}
                        {props.screenWidth < 600 && <h2 className={(props.theme==='dark') ? "dark " : "light margin_bottom_none "} style={{textAlign: 'center', width: '100%'}}>{props.t('titleShippingConfirmation')}</h2>}
                        {props.screenWidth >= 600 && <h2 className={(props.theme==='dark') ? "dark container_list__title  detail" : "light container_list__title margin_bottom_none detail"} type="text" id="list_name" name="listName">{props.t('titleShippingConfirmation')}</h2>}
                        {props.screenWidth < 600 && <div className="margin_bottom_10px"></div>}
                        {props.screenWidth < 600 && <Button variant="contained" className={'button_container__button width_140px align_left'} onClick={()=>{props.switchForm(false);}}>{props.t('back')}</Button>}

                    </div>
        } else if(props.hideSidebar === false){      
            return <div>
                {props.screenWidth >= 600 && <Link to={"/"+props.lang+"/shipping"}>
                    <Button type="button" className="button_container__button  align_left width_140px">{props.t('shippingLog')}</Button>
                </Link>}

                {props.screenWidth < 600 && <h2 className={(props.theme==='dark') ? "dark " : "light margin_bottom_none "} style={{textAlign: 'center', width: '100%'}}>{props.t('titleShippingConfirmation')}</h2>}
                {props.screenWidth >= 600 && <h2 className={(props.theme==='dark') ? "dark container_list__title  detail" : "light container_list__title margin_bottom_none detail"} type="text" id="list_name" name="listName">{props.t('titleShippingConfirmation')}</h2>}
                {props.screenWidth < 600 && <div><Link to={"/"+props.lang+"/shipping"}>
                    <Button type="button" className="button_container__button  align_left _100perc">{props.t('shippingLog')}</Button>
                </Link></div>}
            </div>
        } else {            
            return <div>
                <h2 className={(props.theme==='dark') ? "dark container_list__title" : "light container_list__title"} 
                    type="text" 
                    id="list_name" 
                    name="listName">{props.t('titleShippingConfirmation')}</h2>
            </div>
        }
    }
  return (
    <div style={{padding: 5}} className='shipping'>       
 
        { <div className="container_list__title">
                {showBackButton()}
            </div>}
        {loading && <div className={"container_list__title " + props.theme} style={{display:"flex", justifyContent: "space-evenly", marginTop: 5, border: 1}}>
                        <TailSpin 
                            height="40"
                            width="40"
                            color={Colors.sft_blue}
                            ariaLabel='loading'
                        />
                    </div>}
        {!loading && <div className="app-content shipping">
           {(props.error === '' ? (fileUrl !== '' ? <embed src={fileUrl} height="500" style={{width: (props.screenWidth < 800 ? '100%' : 800)}} type="application/pdf"></embed> : "Label succesvol aangemaakt") : props.error)} </div>}        
    </div>
  );
}

export default ShippingPrint;
