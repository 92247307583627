import React from 'react';

const ProgVarTable = (props) =>  {  
    const progVars = props.progVars;

    const getInfoTable = (target) => {
        switch (target){
            case "advance":
                return (
                    <table className='_100perc textAlignLeft '>
                        <thead>
                            <tr className='sftBorderBottom'>
                                <th className='sftBorderBottom textAlignLeft width_250px'>
                                    &nbsp; Variable
                                </th>
                                <th className='sftBorderBottom textAlignLeft'>
                                    &nbsp; Value
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {progVars['voorschotbon_hoofd1'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['voorschotbon_hoofd1']);}}>voorschotbon_hoofd1</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['voorschotbon_hoofd1']);}}>{progVars['voorschotbon_hoofd1']}</td>
                            </tr>}
                            {progVars['voorschotbon_hoofd2'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['voorschotbon_hoofd2']);}}>voorschotbon_hoofd2</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['voorschotbon_hoofd2']);}}>{progVars['voorschotbon_hoofd2']}</td>
                            </tr>}
                            {progVars['voorschotbon_hoofd3'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['voorschotbon_hoofd3']);}}>voorschotbon_hoofd3</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['voorschotbon_hoofd3']);}}>{progVars['voorschotbon_hoofd3']}</td>
                            </tr>}
                            {progVars['voorschotbon_hoofd4'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['voorschotbon_hoofd4']);}}>voorschotbon_hoofd4</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['voorschotbon_hoofd4']);}}>{progVars['voorschotbon_hoofd4']}</td>
                            </tr>}
                            {progVars['voorschotbon_hoofd5'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['voorschotbon_hoofd5']);}}>voorschotbon_hoofd5</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['voorschotbon_hoofd5']);}}>{progVars['voorschotbon_hoofd5']}</td>
                            </tr>}
                            {progVars['voorschotbon_hoofd6'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['voorschotbon_hoofd6']);}}>voorschotbon_hoofd6</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['voorschotbon_hoofd6']);}}>{progVars['voorschotbon_hoofd6']}</td>
                            </tr>}
                            {progVars['voorschotbon_hoofd7'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['voorschotbon_hoofd7']);}}>voorschotbon_hoofd7</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['voorschotbon_hoofd7']);}}>{progVars['voorschotbon_hoofd7']}</td>
                            </tr>}
                            {progVars['voorschotbon_voet1'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['voorschotbon_voet1']);}}>voorschotbon_voet1</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['voorschotbon_voet1']);}}>{progVars['voorschotbon_voet1']}</td>
                            </tr>}
                            {progVars['voorschotbon_voet2'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['voorschotbon_voet2']);}}>voorschotbon_voet2</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['voorschotbon_voet2']);}}>{progVars['voorschotbon_voet2']}</td>
                            </tr>}
                            {progVars['voorschotbon_voet3'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['voorschotbon_voet3']);}}>voorschotbon_voet3</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['voorschotbon_voet3']);}}>{progVars['voorschotbon_voet3']}</td>
                            </tr>}
                            {progVars['voorschotbon_voet4'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['voorschotbon_voet4']);}}>voorschotbon_voet4</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['voorschotbon_voet4']);}}>{progVars['voorschotbon_voet4']}</td>
                            </tr>}
                            {progVars['voorschotbon_voet5'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['voorschotbon_voet5']);}}>voorschotbon_voet5</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['voorschotbon_voet5']);}}>{progVars['voorschotbon_voet5']}</td>
                            </tr>}
                            {progVars['voorschotbon_voet6'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['voorschotbon_voet6']);}}>voorschotbon_voet6</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['voorschotbon_voet6']);}}>{progVars['voorschotbon_voet6']}</td>
                            </tr>}
                            {progVars['voorschotbon_voet7'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['voorschotbon_voet7']);}}>voorschotbon_voet7</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['voorschotbon_voet7']);}}>{progVars['voorschotbon_voet7']}</td>
                            </tr>}
                            {progVars['Voorschotbon_Voet8'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Voorschotbon_Voet8']);}}>Voorschotbon_Voet8</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Voorschotbon_Voet8']);}}>{progVars['Voorschotbon_Voet8']}</td>
                            </tr>}
                            {progVars['Voorschotbon_Voet9'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Voorschotbon_Voet9']);}}>Voorschotbon_Voet9</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Voorschotbon_Voet9']);}}>{progVars['Voorschotbon_Voet9']}</td>
                            </tr>}
                            {progVars['Voorschotbon_Voet10'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Voorschotbon_Voet10']);}}>Voorschotbon_Voet10</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Voorschotbon_Voet10']);}}>{progVars['Voorschotbon_Voet10']}</td>
                            </tr>}
                            {progVars['Voorschotbon_Voet11'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Voorschotbon_Voet11']);}}>Voorschotbon_Voet11</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Voorschotbon_Voet11']);}}>{progVars['Voorschotbon_Voet11']}</td>
                            </tr>}
                            {progVars['Voorschotbon_Voet12'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Voorschotbon_Voet12']);}}>Voorschotbon_Voet12</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Voorschotbon_Voet12']);}}>{progVars['Voorschotbon_Voet12']}</td>
                            </tr>}
                            {progVars['Voorschotbon_Voet13'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Voorschotbon_Voet13']);}}>Voorschotbon_Voet13</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Voorschotbon_Voet13']);}}>{progVars['Voorschotbon_Voet13']}</td>
                            </tr>}
                            {progVars['Voorschotbon_Voet14'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Voorschotbon_Voet14']);}}>Voorschotbon_Voet14</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Voorschotbon_Voet14']);}}>{progVars['Voorschotbon_Voet14']}</td>
                            </tr>}
                            {progVars['Voorschotbon_Voet15'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Voorschotbon_Voet15']);}}>Voorschotbon_Voet15</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Voorschotbon_Voet15']);}}>{progVars['Voorschotbon_Voet15']}</td>
                            </tr>}
                            {progVars['Voorschotbon_Voet16'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Voorschotbon_Voet16']);}}>Voorschotbon_Voet16</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Voorschotbon_Voet16']);}}>{progVars['Voorschotbon_Voet16']}</td>
                            </tr>}
                            {progVars['Voorschotbon_Voet17'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Voorschotbon_Voet17']);}}>Voorschotbon_Voet17</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Voorschotbon_Voet17']);}}>{progVars['Voorschotbon_Voet17']}</td>
                            </tr>}
                            {progVars['Voorschotbon_Voet18'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Voorschotbon_Voet18']);}}>Voorschotbon_Voet18</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Voorschotbon_Voet18']);}}>{progVars['Voorschotbon_Voet18']}</td>
                            </tr>}
                            {progVars['Voorschotbon_Voet19'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Voorschotbon_Voet19']);}}>Voorschotbon_Voet19</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Voorschotbon_Voet19']);}}>{progVars['Voorschotbon_Voet19']}</td>
                            </tr>}
                            {progVars['Voorschotbon_Voet20'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Voorschotbon_Voet20']);}}>Voorschotbon_Voet20</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Voorschotbon_Voet20']);}}>{progVars['Voorschotbon_Voet20']}</td>
                            </tr>}
                            {progVars['VoorschotAantalDagen'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText('[VoorschotAantalDagen] ');}}>VoorschotAantalDagen</td>
                                <td onClick={()=>{navigator.clipboard.writeText('[VoorschotAantalDagen] ');}}>{progVars['VoorschotAantalDagen']}</td>
                            </tr>}
                        </tbody>
                    </table>
                );
            case "retouch":
                return (
                    <table className='_100perc textAlignLeft '>
                        <thead>
                            <tr className='sftBorderBottom'>
                                <th className='sftBorderBottom textAlignLeft width_250px'>
                                    &nbsp; Variable
                                </th>
                                <th className='sftBorderBottom textAlignLeft'>
                                    &nbsp; Value
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {progVars['Retouche_hoofd1'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Retouche_hoofd1']);}}>Retouche_hoofd1</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Retouche_hoofd1']);}}>{progVars['Retouche_hoofd1']}</td>
                            </tr>}
                            {progVars['Retouche_hoofd2'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Retouche_hoofd2']);}}>Retouche_hoofd2</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Retouche_hoofd2']);}}>{progVars['Retouche_hoofd2']}</td>
                            </tr>}
                            {progVars['Retouche_hoofd3'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Retouche_hoofd3']);}}>Retouche_hoofd3</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Retouche_hoofd3']);}}>{progVars['Retouche_hoofd3']}</td>
                            </tr>}
                            {progVars['Retouche_hoofd4'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Retouche_hoofd4']);}}>Retouche_hoofd4</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Retouche_hoofd4']);}}>{progVars['Retouche_hoofd4']}</td>
                            </tr>}
                            {progVars['Retouche_hoofd5'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Retouche_hoofd5']);}}>Retouche_hoofd5</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Retouche_hoofd5']);}}>{progVars['Retouche_hoofd5']}</td>
                            </tr>}
                            {progVars['Retouche_hoofd6'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Retouche_hoofd6']);}}>Retouche_hoofd6</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Retouche_hoofd6']);}}>{progVars['Retouche_hoofd6']}</td>
                            </tr>}
                            {progVars['Retouche_hoofd7'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Retouche_hoofd7']);}}>Retouche_hoofd7</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Retouche_hoofd7']);}}>{progVars['Retouche_hoofd7']}</td>
                            </tr>}
                            {progVars['Retouche_Voet1'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Retouche_Voet1']);}}>Retouche_Voet1</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Retouche_Voet1']);}}>{progVars['Retouche_Voet1']}</td>
                            </tr>}
                            {progVars['Retouche_Voet2'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Retouche_Voet2']);}}>Retouche_Voet2</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Retouche_Voet2']);}}>{progVars['Retouche_Voet2']}</td>
                            </tr>}
                            {progVars['Retouche_Voet3'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Retouche_Voet3']);}}>Retouche_Voet3</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Retouche_Voet3']);}}>{progVars['Retouche_Voet3']}</td>
                            </tr>}
                            {progVars['Retouche_Voet4'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Retouche_Voet4']);}}>Retouche_Voet4</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Retouche_Voet4']);}}>{progVars['Retouche_Voet4']}</td>
                            </tr>}
                            {progVars['Retouche_Voet5'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Retouche_Voet5']);}}>Retouche_Voet5</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Retouche_Voet5']);}}>{progVars['Retouche_Voet5']}</td>
                            </tr>}
                            {progVars['Retouche_Voet6'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Retouche_Voet6']);}}>Retouche_Voet6</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Retouche_Voet6']);}}>{progVars['Retouche_Voet6']}</td>
                            </tr>}
                            {progVars['Retouche_Voet7'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Retouche_Voet7']);}}>Retouche_Voet7</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Retouche_Voet7']);}}>{progVars['Retouche_Voet7']}</td>
                            </tr>}
                            {progVars['Retouche_Voet8'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Retouche_Voet8']);}}>Retouche_Voet8</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Retouche_Voet8']);}}>{progVars['Retouche_Voet8']}</td>
                            </tr>}
                            {progVars['Retouche_Voet9'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Retouche_Voet9']);}}>Retouche_Voet9</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Retouche_Voet9']);}}>{progVars['Retouche_Voet9']}</td>
                            </tr>}
                            {progVars['Retouche_Voet10'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Retouche_Voet10']);}}>Retouche_Voet10</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Retouche_Voet10']);}}>{progVars['Retouche_Voet10']}</td>
                            </tr>}
                            {progVars['Retouche_Voet11'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Retouche_Voet11']);}}>Retouche_Voet11</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Retouche_Voet11']);}}>{progVars['Retouche_Voet11']}</td>
                            </tr>}
                            {progVars['Retouche_Voet12'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Retouche_Voet12']);}}>Retouche_Voet12</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Retouche_Voet12']);}}>{progVars['Retouche_Voet12']}</td>
                            </tr>}
                            {progVars['Retouche_Voet13'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Retouche_Voet13']);}}>Retouche_Voet13</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Retouche_Voet13']);}}>{progVars['Retouche_Voet13']}</td>
                            </tr>}
                            {progVars['Retouche_Voet14'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Retouche_Voet14']);}}>Retouche_Voet14</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Retouche_Voet14']);}}>{progVars['Retouche_Voet14']}</td>
                            </tr>}
                            {progVars['Retouche_Voet15'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Retouche_Voet15']);}}>Retouche_Voet15</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Retouche_Voet15']);}}>{progVars['Retouche_Voet15']}</td>
                            </tr>}
                            {progVars['Retouche_Voet16'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Retouche_Voet16']);}}>Retouche_Voet16</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Retouche_Voet16']);}}>{progVars['Retouche_Voet16']}</td>
                            </tr>}
                            {progVars['Retouche_Voet17'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Retouche_Voet17']);}}>Retouche_Voet17</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Retouche_Voet17']);}}>{progVars['Retouche_Voet17']}</td>
                            </tr>}
                            {progVars['Retouche_Voet18'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Retouche_Voet18']);}}>Retouche_Voet18</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Retouche_Voet18']);}}>{progVars['Retouche_Voet18']}</td>
                            </tr>}
                            {progVars['Retouche_Voet19'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Retouche_Voet19']);}}>Retouche_Voet19</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Retouche_Voet19']);}}>{progVars['Retouche_Voet19']}</td>
                            </tr>}
                            {progVars['Retouche_Voet20'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Retouche_Voet20']);}}>Retouche_Voet20</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Retouche_Voet20']);}}>{progVars['Retouche_Voet20']}</td>
                            </tr>}
                            {progVars['retouche_dagen'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText('[retouche_dagen] ');}}>retouche_dagen</td>
                                <td onClick={()=>{navigator.clipboard.writeText('[retouche_dagen] ');}}>{progVars['retouche_dagen']}</td>
                            </tr>}
                        </tbody>
                    </table>
                );
            case "commercialvoucher":
            case "voucher":
                return (
                    <table className='_100perc textAlignLeft '>
                        <thead>
                            <tr className='sftBorderBottom'>
                                <th className='sftBorderBottom textAlignLeft width_250px'>
                                    &nbsp; Variable
                                </th>
                                <th className='sftBorderBottom textAlignLeft'>
                                    &nbsp; Value
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {progVars['waardebon_hoofd1'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['waardebon_hoofd1']);}}>waardebon_hoofd1</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['waardebon_hoofd1']);}}>{progVars['waardebon_hoofd1']}</td>
                            </tr>}
                            {progVars['waardebon_hoofd2'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['waardebon_hoofd2']);}}>waardebon_hoofd2</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['waardebon_hoofd2']);}}>{progVars['waardebon_hoofd2']}</td>
                            </tr>}
                            {progVars['waardebon_hoofd3'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['waardebon_hoofd3']);}}>waardebon_hoofd3</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['waardebon_hoofd3']);}}>{progVars['waardebon_hoofd3']}</td>
                            </tr>}
                            {progVars['waardebon_hoofd4'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['waardebon_hoofd4']);}}>waardebon_hoofd4</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['waardebon_hoofd4']);}}>{progVars['waardebon_hoofd4']}</td>
                            </tr>}
                            {progVars['waardebon_hoofd5'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['waardebon_hoofd5']);}}>waardebon_hoofd5</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['waardebon_hoofd5']);}}>{progVars['waardebon_hoofd5']}</td>
                            </tr>}
                            {progVars['waardebon_hoofd6'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['waardebon_hoofd6']);}}>waardebon_hoofd6</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['waardebon_hoofd6']);}}>{progVars['waardebon_hoofd6']}</td>
                            </tr>}
                            {progVars['waardebon_hoofd7'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['waardebon_hoofd7']);}}>waardebon_hoofd7</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['waardebon_hoofd7']);}}>{progVars['waardebon_hoofd7']}</td>
                            </tr>}
                            {progVars['waardebon_voet1'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['waardebon_voet1']);}}>waardebon_voet1</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['waardebon_voet1']);}}>{progVars['waardebon_voet1']}</td>
                            </tr>}
                            {progVars['waardebon_voet2'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['waardebon_voet2']);}}>waardebon_voet2</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['waardebon_voet2']);}}>{progVars['waardebon_voet2']}</td>
                            </tr>}
                            {progVars['waardebon_voet3'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['waardebon_voet3']);}}>waardebon_voet3</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['waardebon_voet3']);}}>{progVars['waardebon_voet3']}</td>
                            </tr>}
                            {progVars['waardebon_voet4'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['waardebon_voet4']);}}>waardebon_voet4</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['waardebon_voet4']);}}>{progVars['waardebon_voet4']}</td>
                            </tr>}
                            {progVars['waardebon_voet5'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['waardebon_voet5']);}}>waardebon_voet5</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['waardebon_voet5']);}}>{progVars['waardebon_voet5']}</td>
                            </tr>}
                            {progVars['waardebon_voet6'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['waardebon_voet6']);}}>waardebon_voet6</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['waardebon_voet6']);}}>{progVars['waardebon_voet6']}</td>
                            </tr>}
                            {progVars['waardebon_voet7'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['waardebon_voet7']);}}>waardebon_voet7</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['waardebon_voet7']);}}>{progVars['waardebon_voet7']}</td>
                            </tr>}
                            {progVars['waardebon_Voet8'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['waardebon_Voet8']);}}>waardebon_Voet8</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['waardebon_Voet8']);}}>{progVars['waardebon_Voet8']}</td>
                            </tr>}
                            {progVars['waardebon_Voet9'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['waardebon_Voet9']);}}>waardebon_Voet9</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['waardebon_Voet9']);}}>{progVars['waardebon_Voet9']}</td>
                            </tr>}
                            {progVars['waardebon_Voet10'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['waardebon_Voet10']);}}>waardebon_Voet10</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['waardebon_Voet10']);}}>{progVars['waardebon_Voet10']}</td>
                            </tr>}
                            {progVars['waardebon_Voet11'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['waardebon_Voet11']);}}>waardebon_Voet11</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['waardebon_Voet11']);}}>{progVars['waardebon_Voet11']}</td>
                            </tr>}
                            {progVars['waardebon_Voet12'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['waardebon_Voet12']);}}>waardebon_Voet12</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['waardebon_Voet12']);}}>{progVars['waardebon_Voet12']}</td>
                            </tr>}
                            {progVars['waardebon_Voet13'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['waardebon_Voet13']);}}>waardebon_Voet13</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['waardebon_Voet13']);}}>{progVars['waardebon_Voet13']}</td>
                            </tr>}
                            {progVars['waardebon_Voet14'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['waardebon_Voet14']);}}>waardebon_Voet14</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['waardebon_Voet14']);}}>{progVars['waardebon_Voet14']}</td>
                            </tr>}
                            {progVars['waardebon_Voet15'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['waardebon_Voet15']);}}>waardebon_Voet15</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['waardebon_Voet15']);}}>{progVars['waardebon_Voet15']}</td>
                            </tr>}
                            {progVars['waardebon_Voet16'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['waardebon_Voet16']);}}>waardebon_Voet16</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['waardebon_Voet16']);}}>{progVars['waardebon_Voet16']}</td>
                            </tr>}
                            {progVars['waardebon_Voet17'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['waardebon_Voet17']);}}>waardebon_Voet17</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['waardebon_Voet17']);}}>{progVars['waardebon_Voet17']}</td>
                            </tr>}
                            {progVars['waardebon_Voet18'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['waardebon_Voet18']);}}>waardebon_Voet18</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['waardebon_Voet18']);}}>{progVars['waardebon_Voet18']}</td>
                            </tr>}
                            {progVars['waardebon_Voet19'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['waardebon_Voet19']);}}>waardebon_Voet19</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['waardebon_Voet19']);}}>{progVars['waardebon_Voet19']}</td>
                            </tr>}
                            {progVars['waardebon_Voet20'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['waardebon_Voet20']);}}>waardebon_Voet20</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['waardebon_Voet20']);}}>{progVars['waardebon_Voet20']}</td>
                            </tr>}
                            {progVars['WaardeBonAantalDagen'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText('[WaardeBonAantalDagen] ');}}>WaardeBonAantalDagen</td>
                                <td onClick={()=>{navigator.clipboard.writeText('[WaardeBonAantalDagen] ');}}>{progVars['WaardeBonAantalDagen']}</td>
                            </tr>}
                        </tbody>
                    </table>
                );
            case "giftvoucher":
                return (
                    <table className='_100perc textAlignLeft '>
                        <thead>
                            <tr className='sftBorderBottom'>
                                <th className='sftBorderBottom textAlignLeft width_250px'>
                                    &nbsp; Variable
                                </th>
                                <th className='sftBorderBottom textAlignLeft'>
                                    &nbsp; Value
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {progVars['cadeaucheque_hoofd1'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['cadeaucheque_hoofd1']);}}>cadeaucheque_hoofd1</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['cadeaucheque_hoofd1']);}}>{progVars['cadeaucheque_hoofd1']}</td>
                            </tr>}
                            {progVars['cadeaucheque_hoofd2'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['cadeaucheque_hoofd2']);}}>cadeaucheque_hoofd2</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['cadeaucheque_hoofd2']);}}>{progVars['cadeaucheque_hoofd2']}</td>
                            </tr>}
                            {progVars['cadeaucheque_hoofd3'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['cadeaucheque_hoofd3']);}}>cadeaucheque_hoofd3</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['cadeaucheque_hoofd3']);}}>{progVars['cadeaucheque_hoofd3']}</td>
                            </tr>}
                            {progVars['cadeaucheque_hoofd4'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['cadeaucheque_hoofd4']);}}>cadeaucheque_hoofd4</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['cadeaucheque_hoofd4']);}}>{progVars['cadeaucheque_hoofd4']}</td>
                            </tr>}
                            {progVars['cadeaucheque_hoofd5'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['cadeaucheque_hoofd5']);}}>cadeaucheque_hoofd5</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['cadeaucheque_hoofd5']);}}>{progVars['cadeaucheque_hoofd5']}</td>
                            </tr>}
                            {progVars['cadeaucheque_hoofd6'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['cadeaucheque_hoofd6']);}}>cadeaucheque_hoofd6</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['cadeaucheque_hoofd6']);}}>{progVars['cadeaucheque_hoofd6']}</td>
                            </tr>}
                            {progVars['cadeaucheque_hoofd7'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['cadeaucheque_hoofd7']);}}>cadeaucheque_hoofd7</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['cadeaucheque_hoofd7']);}}>{progVars['cadeaucheque_hoofd7']}</td>
                            </tr>}
                            {progVars['cadeaucheque_voet1'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['cadeaucheque_voet1']);}}>cadeaucheque_voet1</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['cadeaucheque_voet1']);}}>{progVars['cadeaucheque_voet1']}</td>
                            </tr>}
                            {progVars['cadeaucheque_voet2'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['cadeaucheque_voet2']);}}>cadeaucheque_voet2</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['cadeaucheque_voet2']);}}>{progVars['cadeaucheque_voet2']}</td>
                            </tr>}
                            {progVars['cadeaucheque_voet3'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['cadeaucheque_voet3']);}}>cadeaucheque_voet3</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['cadeaucheque_voet3']);}}>{progVars['cadeaucheque_voet3']}</td>
                            </tr>}
                            {progVars['cadeaucheque_voet4'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['cadeaucheque_voet4']);}}>cadeaucheque_voet4</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['cadeaucheque_voet4']);}}>{progVars['cadeaucheque_voet4']}</td>
                            </tr>}
                            {progVars['cadeaucheque_voet5'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['cadeaucheque_voet5']);}}>cadeaucheque_voet5</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['cadeaucheque_voet5']);}}>{progVars['cadeaucheque_voet5']}</td>
                            </tr>}
                            {progVars['cadeaucheque_voet6'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['cadeaucheque_voet6']);}}>cadeaucheque_voet6</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['cadeaucheque_voet6']);}}>{progVars['cadeaucheque_voet6']}</td>
                            </tr>}
                            {progVars['cadeaucheque_voet7'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['cadeaucheque_voet7']);}}>cadeaucheque_voet7</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['cadeaucheque_voet7']);}}>{progVars['cadeaucheque_voet7']}</td>
                            </tr>}
                            {progVars['cadeaucheque_Voet8'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['cadeaucheque_Voet8']);}}>cadeaucheque_Voet8</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['cadeaucheque_Voet8']);}}>{progVars['cadeaucheque_Voet8']}</td>
                            </tr>}
                            {progVars['cadeaucheque_Voet9'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['cadeaucheque_Voet9']);}}>cadeaucheque_Voet9</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['cadeaucheque_Voet9']);}}>{progVars['cadeaucheque_Voet9']}</td>
                            </tr>}
                            {progVars['cadeaucheque_Voet10'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['cadeaucheque_Voet10']);}}>cadeaucheque_Voet10</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['cadeaucheque_Voet10']);}}>{progVars['cadeaucheque_Voet10']}</td>
                            </tr>}
                            {progVars['cadeaucheque_Voet11'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['cadeaucheque_Voet11']);}}>cadeaucheque_Voet11</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['cadeaucheque_Voet11']);}}>{progVars['cadeaucheque_Voet11']}</td>
                            </tr>}
                            {progVars['cadeaucheque_Voet12'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['cadeaucheque_Voet12']);}}>cadeaucheque_Voet12</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['cadeaucheque_Voet12']);}}>{progVars['cadeaucheque_Voet12']}</td>
                            </tr>}
                            {progVars['cadeaucheque_Voet13'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['cadeaucheque_Voet13']);}}>cadeaucheque_Voet13</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['cadeaucheque_Voet13']);}}>{progVars['cadeaucheque_Voet13']}</td>
                            </tr>}
                            {progVars['cadeaucheque_Voet14'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['cadeaucheque_Voet14']);}}>cadeaucheque_Voet14</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['cadeaucheque_Voet14']);}}>{progVars['cadeaucheque_Voet14']}</td>
                            </tr>}
                            {progVars['cadeaucheque_Voet15'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['cadeaucheque_Voet15']);}}>cadeaucheque_Voet15</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['cadeaucheque_Voet15']);}}>{progVars['cadeaucheque_Voet15']}</td>
                            </tr>}
                            {progVars['cadeaucheque_Voet16'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['cadeaucheque_Voet16']);}}>cadeaucheque_Voet16</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['cadeaucheque_Voet16']);}}>{progVars['cadeaucheque_Voet16']}</td>
                            </tr>}
                            {progVars['cadeaucheque_Voet17'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['cadeaucheque_Voet17']);}}>cadeaucheque_Voet17</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['cadeaucheque_Voet17']);}}>{progVars['cadeaucheque_Voet17']}</td>
                            </tr>}
                            {progVars['cadeaucheque_Voet18'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['cadeaucheque_Voet18']);}}>cadeaucheque_Voet18</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['cadeaucheque_Voet18']);}}>{progVars['cadeaucheque_Voet18']}</td>
                            </tr>}
                            {progVars['cadeaucheque_Voet19'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['cadeaucheque_Voet19']);}}>cadeaucheque_Voet19</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['cadeaucheque_Voet19']);}}>{progVars['cadeaucheque_Voet19']}</td>
                            </tr>}
                            {progVars['cadeaucheque_Voet20'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['cadeaucheque_Voet20']);}}>cadeaucheque_Voet20</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['cadeaucheque_Voet20']);}}>{progVars['cadeaucheque_Voet20']}</td>
                            </tr>}
                            {progVars['CadeauChequeAantalDagen'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText('[CadeauChequeAantalDagen] ');}}>CadeauChequeAantalDagen</td>
                                <td onClick={()=>{navigator.clipboard.writeText('[CadeauChequeAantalDagen] ');}}>{progVars['CadeauChequeAantalDagen']}</td>
                            </tr>}
                        </tbody>
                    </table>
                );
            case "customercardvoucher":
                return (
                    <table className='_100perc textAlignLeft '>
                        <thead>
                            <tr className='sftBorderBottom'>
                                <th className='sftBorderBottom textAlignLeft width_250px'>
                                    &nbsp; Variable
                                </th>
                                <th className='sftBorderBottom textAlignLeft'>
                                    &nbsp; Value
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {progVars['klkaart_hoofd1'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['klkaart_hoofd1']);}}>klkaart_hoofd1</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['klkaart_hoofd1']);}}>{progVars['klkaart_hoofd1']}</td>
                            </tr>}
                            {progVars['klkaart_hoofd2'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['klkaart_hoofd2']);}}>klkaart_hoofd2</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['klkaart_hoofd2']);}}>{progVars['klkaart_hoofd2']}</td>
                            </tr>}
                            {progVars['klkaart_hoofd3'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['klkaart_hoofd3']);}}>klkaart_hoofd3</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['klkaart_hoofd3']);}}>{progVars['klkaart_hoofd3']}</td>
                            </tr>}
                            {progVars['klkaart_hoofd4'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['klkaart_hoofd4']);}}>klkaart_hoofd4</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['klkaart_hoofd4']);}}>{progVars['klkaart_hoofd4']}</td>
                            </tr>}
                            {progVars['klkaart_hoofd5'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['klkaart_hoofd5']);}}>klkaart_hoofd5</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['klkaart_hoofd5']);}}>{progVars['klkaart_hoofd5']}</td>
                            </tr>}
                            {progVars['klkaart_hoofd6'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['klkaart_hoofd6']);}}>klkaart_hoofd6</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['klkaart_hoofd6']);}}>{progVars['klkaart_hoofd6']}</td>
                            </tr>}
                            {progVars['klkaart_hoofd7'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['klkaart_hoofd7']);}}>klkaart_hoofd7</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['klkaart_hoofd7']);}}>{progVars['klkaart_hoofd7']}</td>
                            </tr>}
                            {progVars['klkaart_voet1'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['klkaart_voet1']);}}>klkaart_voet1</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['klkaart_voet1']);}}>{progVars['klkaart_voet1']}</td>
                            </tr>}
                            {progVars['klkaart_voet2'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['klkaart_voet2']);}}>klkaart_voet2</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['klkaart_voet2']);}}>{progVars['klkaart_voet2']}</td>
                            </tr>}
                            {progVars['klkaart_voet3'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['klkaart_voet3']);}}>klkaart_voet3</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['klkaart_voet3']);}}>{progVars['klkaart_voet3']}</td>
                            </tr>}
                            {progVars['klkaart_voet4'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['klkaart_voet4']);}}>klkaart_voet4</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['klkaart_voet4']);}}>{progVars['klkaart_voet4']}</td>
                            </tr>}
                            {progVars['klkaart_voet5'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['klkaart_voet5']);}}>klkaart_voet5</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['klkaart_voet5']);}}>{progVars['klkaart_voet5']}</td>
                            </tr>}
                            {progVars['klkaart_voet6'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['klkaart_voet6']);}}>klkaart_voet6</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['klkaart_voet6']);}}>{progVars['klkaart_voet6']}</td>
                            </tr>}
                            {progVars['klkaart_voet7'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['klkaart_voet7']);}}>klkaart_voet7</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['klkaart_voet7']);}}>{progVars['klkaart_voet7']}</td>
                            </tr>}
                            {progVars['klkaart_voet8'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['klkaart_voet8']);}}>klkaart_voet8</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['klkaart_voet8']);}}>{progVars['klkaart_voet8']}</td>
                            </tr>}
                            {progVars['klkaart_voet9'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['klkaart_voet9']);}}>klkaart_voet9</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['klkaart_voet9']);}}>{progVars['klkaart_voet9']}</td>
                            </tr>}
                            {progVars['klkaart_voet10'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['klkaart_voet10']);}}>klkaart_voet10</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['klkaart_voet10']);}}>{progVars['klkaart_voet10']}</td>
                            </tr>}
                            {progVars['klkaart_voet11'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['klkaart_voet11']);}}>klkaart_voet11</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['klkaart_voet11']);}}>{progVars['klkaart_voet11']}</td>
                            </tr>}
                            {progVars['klkaart_voet12'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['klkaart_voet12']);}}>klkaart_voet12</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['klkaart_voet12']);}}>{progVars['klkaart_voet12']}</td>
                            </tr>}
                            {progVars['klkaart_voet13'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['klkaart_voet13']);}}>klkaart_voet13</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['klkaart_voet13']);}}>{progVars['klkaart_voet13']}</td>
                            </tr>}
                            {progVars['klkaart_voet14'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['klkaart_voet14']);}}>klkaart_voet14</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['klkaart_voet14']);}}>{progVars['klkaart_voet14']}</td>
                            </tr>}
                            {progVars['klkaart_voet15'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['klkaart_voet15']);}}>klkaart_voet15</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['klkaart_voet15']);}}>{progVars['klkaart_voet15']}</td>
                            </tr>}
                            {progVars['klkaart_voet16'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['klkaart_voet16']);}}>klkaart_voet16</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['klkaart_voet16']);}}>{progVars['klkaart_voet16']}</td>
                            </tr>}
                            {progVars['klkaart_voet17'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['klkaart_voet17']);}}>klkaart_voet17</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['klkaart_voet17']);}}>{progVars['klkaart_voet17']}</td>
                            </tr>}
                            {progVars['klkaart_voet18'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['klkaart_voet18']);}}>klkaart_voet18</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['klkaart_voet18']);}}>{progVars['klkaart_voet18']}</td>
                            </tr>}
                            {progVars['klkaart_voet19'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['klkaart_voet19']);}}>klkaart_voet19</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['klkaart_voet19']);}}>{progVars['klkaart_voet19']}</td>
                            </tr>}
                            {progVars['klkaart_voet20'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['klkaart_voet20']);}}>klkaart_voet20</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['klkaart_voet20']);}}>{progVars['klkaart_voet20']}</td>
                            </tr>}
                            {progVars['KlKaartType32AantalDagen'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText('[KlKaartType32AantalDagen] ');}}>KlKaartType32AantalDagen</td>
                                <td onClick={()=>{navigator.clipboard.writeText('[KlKaartType32AantalDagen] ');}}>{progVars['KlKaartType32AantalDagen']}</td>
                            </tr>}
                            {progVars['KlkaartGeldigVanafAantalDagen'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText('[KlkaartGeldigVanafAantalDagen] ');}}>KlkaartGeldigVanafAantalDagen</td>
                                <td onClick={()=>{navigator.clipboard.writeText('[KlkaartGeldigVanafAantalDagen] ');}}>{progVars['KlkaartGeldigVanafAantalDagen']}</td>
                            </tr>}
                        </tbody>
                    </table>
                );
            case "receipt":
                return (
                    <table className='_100perc textAlignLeft '>
                        <thead>
                            <tr className='sftBorderBottom'>
                                <th className='sftBorderBottom textAlignLeft width_250px'>
                                    &nbsp; Variable
                                </th>
                                <th className='sftBorderBottom textAlignLeft'>
                                    &nbsp; Value
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {progVars['kasticket_hoofd1'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['kasticket_hoofd1']);}}>kasticket_hoofd1</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['kasticket_hoofd1']);}}>{progVars['kasticket_hoofd1']}</td>
                            </tr>}
                            {progVars['kasticket_hoofd2'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['kasticket_hoofd2']);}}>kasticket_hoofd2</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['kasticket_hoofd2']);}}>{progVars['kasticket_hoofd2']}</td>
                            </tr>}
                            {progVars['kasticket_hoofd3'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['kasticket_hoofd3']);}}>kasticket_hoofd3</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['kasticket_hoofd3']);}}>{progVars['kasticket_hoofd3']}</td>
                            </tr>}
                            {progVars['kasticket_hoofd4'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['kasticket_hoofd4']);}}>kasticket_hoofd4</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['kasticket_hoofd4']);}}>{progVars['kasticket_hoofd4']}</td>
                            </tr>}
                            {progVars['kasticket_hoofd5'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['kasticket_hoofd5']);}}>kasticket_hoofd5</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['kasticket_hoofd5']);}}>{progVars['kasticket_hoofd5']}</td>
                            </tr>}
                            {progVars['kasticket_hoofd6'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['kasticket_hoofd6']);}}>kasticket_hoofd6</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['kasticket_hoofd6']);}}>{progVars['kasticket_hoofd6']}</td>
                            </tr>}
                            {progVars['kasticket_hoofd7'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['kasticket_hoofd7']);}}>kasticket_hoofd7</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['kasticket_hoofd7']);}}>{progVars['kasticket_hoofd7']}</td>
                            </tr>}
                            {progVars['kasticket_voet1'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['kasticket_voet1']);}}>kasticket_voet1</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['kasticket_voet1']);}}>{progVars['kasticket_voet1']}</td>
                            </tr>}
                            {progVars['kasticket_voet2'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['kasticket_voet2']);}}>kasticket_voet2</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['kasticket_voet2']);}}>{progVars['kasticket_voet2']}</td>
                            </tr>}
                            {progVars['kasticket_voet3'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['kasticket_voet3']);}}>kasticket_voet3</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['kasticket_voet3']);}}>{progVars['kasticket_voet3']}</td>
                            </tr>}
                            {progVars['kasticket_voet4'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['kasticket_voet4']);}}>kasticket_voet4</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['kasticket_voet4']);}}>{progVars['kasticket_voet4']}</td>
                            </tr>}
                            {progVars['kasticket_voet5'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['kasticket_voet5']);}}>kasticket_voet5</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['kasticket_voet5']);}}>{progVars['kasticket_voet5']}</td>
                            </tr>}
                            {progVars['kasticket_voet6'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['kasticket_voet6']);}}>kasticket_voet6</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['kasticket_voet6']);}}>{progVars['kasticket_voet6']}</td>
                            </tr>}
                            {progVars['kasticket_voet7'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['kasticket_voet7']);}}>kasticket_voet7</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['kasticket_voet7']);}}>{progVars['kasticket_voet7']}</td>
                            </tr>}
                            {progVars['kasticket_voet8'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['kasticket_voet8']);}}>kasticket_voet8</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['kasticket_voet8']);}}>{progVars['kasticket_voet8']}</td>
                            </tr>}
                            {progVars['kasticket_voet9'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['kasticket_voet9']);}}>kasticket_voet9</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['kasticket_voet9']);}}>{progVars['kasticket_voet9']}</td>
                            </tr>}
                            {progVars['kasticket_voet10'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['kasticket_voet10']);}}>kasticket_voet10</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['kasticket_voet10']);}}>{progVars['kasticket_voet10']}</td>
                            </tr>}
                            {progVars['kasticket_voet11'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['kasticket_voet11']);}}>kasticket_voet11</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['kasticket_voet11']);}}>{progVars['kasticket_voet11']}</td>
                            </tr>}
                            {progVars['kasticket_voet12'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['kasticket_voet12']);}}>kasticket_voet12</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['kasticket_voet12']);}}>{progVars['kasticket_voet12']}</td>
                            </tr>}
                            {progVars['kasticket_voet13'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['kasticket_voet13']);}}>kasticket_voet13</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['kasticket_voet13']);}}>{progVars['kasticket_voet13']}</td>
                            </tr>}
                            {progVars['kasticket_voet14'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['kasticket_voet14']);}}>kasticket_voet14</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['kasticket_voet14']);}}>{progVars['kasticket_voet14']}</td>
                            </tr>}
                            {progVars['kasticket_voet15'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['kasticket_voet15']);}}>kasticket_voet15</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['kasticket_voet15']);}}>{progVars['kasticket_voet15']}</td>
                            </tr>}
                            {progVars['kasticket_voet16'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['kasticket_voet16']);}}>kasticket_voet16</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['kasticket_voet16']);}}>{progVars['kasticket_voet16']}</td>
                            </tr>}
                            {progVars['kasticket_voet17'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['kasticket_voet17']);}}>kasticket_voet17</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['kasticket_voet17']);}}>{progVars['kasticket_voet17']}</td>
                            </tr>}
                            {progVars['kasticket_voet18'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['kasticket_voet18']);}}>kasticket_voet18</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['kasticket_voet18']);}}>{progVars['kasticket_voet18']}</td>
                            </tr>}
                            {progVars['kasticket_voet19'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['kasticket_voet19']);}}>kasticket_voet19</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['kasticket_voet19']);}}>{progVars['kasticket_voet19']}</td>
                            </tr>}
                            {progVars['kasticket_voet20'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['kasticket_voet20']);}}>kasticket_voet20</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['kasticket_voet20']);}}>{progVars['kasticket_voet20']}</td>
                            </tr>}
                        </tbody>
                    </table>
                );
            case "reservation":
                return (
                    <table className='_100perc textAlignLeft '>
                        <thead>
                            <tr className='sftBorderBottom'>
                                <th className='sftBorderBottom textAlignLeft width_250px'>
                                    &nbsp; Variable
                                </th>
                                <th className='sftBorderBottom textAlignLeft'>
                                    &nbsp; Value
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {progVars['reservatieticket_voet1'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['reservatieticket_voet1']);}}>reservatieticket_voet1</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['reservatieticket_voet1']);}}>{progVars['reservatieticket_voet1']}</td>
                            </tr>}
                            {progVars['reservatieticket_voet2'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['reservatieticket_voet2']);}}>reservatieticket_voet2</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['reservatieticket_voet2']);}}>{progVars['reservatieticket_voet2']}</td>
                            </tr>}
                            {progVars['reservatieticket_voet3'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['reservatieticket_voet3']);}}>reservatieticket_voet3</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['reservatieticket_voet3']);}}>{progVars['reservatieticket_voet3']}</td>
                            </tr>}
                            {progVars['reservatieticket_voet4'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['reservatieticket_voet4']);}}>reservatieticket_voet4</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['reservatieticket_voet4']);}}>{progVars['reservatieticket_voet4']}</td>
                            </tr>}
                            {progVars['reservatieticket_voet5'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['reservatieticket_voet5']);}}>reservatieticket_voet5</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['reservatieticket_voet5']);}}>{progVars['reservatieticket_voet5']}</td>
                            </tr>}
                            {progVars['reservatieticket_voet6'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['reservatieticket_voet6']);}}>reservatieticket_voet6</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['reservatieticket_voet6']);}}>{progVars['reservatieticket_voet6']}</td>
                            </tr>}
                            {progVars['reservatieticket_voet7'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['reservatieticket_voet7']);}}>reservatieticket_voet7</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['reservatieticket_voet7']);}}>{progVars['reservatieticket_voet7']}</td>
                            </tr>}
                            {progVars['reservatieticket_voet8'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['reservatieticket_voet8']);}}>reservatieticket_voet8</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['reservatieticket_voet8']);}}>{progVars['reservatieticket_voet8']}</td>
                            </tr>}
                            {progVars['reservatieticket_voet9'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['reservatieticket_voet9']);}}>reservatieticket_voet9</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['reservatieticket_voet9']);}}>{progVars['reservatieticket_voet9']}</td>
                            </tr>}
                            {progVars['reservatieticket_voet10'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['reservatieticket_voet10']);}}>reservatieticket_voet10</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['reservatieticket_voet10']);}}>{progVars['reservatieticket_voet10']}</td>
                            </tr>}
                            {progVars['reservatieticket_voet11'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['reservatieticket_voet11']);}}>reservatieticket_voet11</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['reservatieticket_voet11']);}}>{progVars['reservatieticket_voet11']}</td>
                            </tr>}
                            {progVars['reservatieticket_voet12'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['reservatieticket_voet12']);}}>reservatieticket_voet12</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['reservatieticket_voet12']);}}>{progVars['reservatieticket_voet12']}</td>
                            </tr>}
                            {progVars['reservatieticket_voet13'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['reservatieticket_voet13']);}}>reservatieticket_voet13</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['reservatieticket_voet13']);}}>{progVars['reservatieticket_voet13']}</td>
                            </tr>}
                            {progVars['reservatieticket_voet14'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['reservatieticket_voet14']);}}>reservatieticket_voet14</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['reservatieticket_voet14']);}}>{progVars['reservatieticket_voet14']}</td>
                            </tr>}
                            {progVars['reservatieticket_voet15'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['reservatieticket_voet15']);}}>reservatieticket_voet15</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['reservatieticket_voet15']);}}>{progVars['reservatieticket_voet15']}</td>
                            </tr>}
                            {progVars['reservatieticket_voet16'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['reservatieticket_voet16']);}}>reservatieticket_voet16</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['reservatieticket_voet16']);}}>{progVars['reservatieticket_voet16']}</td>
                            </tr>}
                            {progVars['reservatieticket_voet17'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['reservatieticket_voet17']);}}>reservatieticket_voet17</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['reservatieticket_voet17']);}}>{progVars['reservatieticket_voet17']}</td>
                            </tr>}
                            {progVars['reservatieticket_voet18'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['reservatieticket_voet18']);}}>reservatieticket_voet18</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['reservatieticket_voet18']);}}>{progVars['reservatieticket_voet18']}</td>
                            </tr>}
                            {progVars['reservatieticket_voet19'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['reservatieticket_voet19']);}}>reservatieticket_voet19</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['reservatieticket_voet19']);}}>{progVars['reservatieticket_voet19']}</td>
                            </tr>}
                            {progVars['reservatieticket_voet20'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['reservatieticket_voet20']);}}>reservatieticket_voet20</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['reservatieticket_voet20']);}}>{progVars['reservatieticket_voet20']}</td>
                            </tr>}
                            {progVars['reservatie_dagen'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText('[reservatie_dagen] ');}}>reservatie_dagen</td>
                                <td onClick={()=>{navigator.clipboard.writeText('[reservatie_dagen] ');}}>{progVars['reservatie_dagen']}</td>
                            </tr>}
                        </tbody>
                    </table>
                );
            case "customerorder":
                return (
                    <table className='_100perc textAlignLeft '>
                        <thead>
                            <tr className='sftBorderBottom'>
                                <th className='sftBorderBottom textAlignLeft width_250px'>
                                    &nbsp; Variable
                                </th>
                                <th className='sftBorderBottom textAlignLeft'>
                                    &nbsp; Value
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {progVars['OrderTicket_voet1'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['OrderTicket_voet1']);}}>OrderTicket_voet1</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['OrderTicket_voet1']);}}>{progVars['OrderTicket_voet1']}</td>
                            </tr>}
                            {progVars['OrderTicket_voet2'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['OrderTicket_voet2']);}}>OrderTicket_voet2</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['OrderTicket_voet2']);}}>{progVars['OrderTicket_voet2']}</td>
                            </tr>}
                            {progVars['OrderTicket_voet3'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['OrderTicket_voet3']);}}>OrderTicket_voet3</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['OrderTicket_voet3']);}}>{progVars['OrderTicket_voet3']}</td>
                            </tr>}
                            {progVars['OrderTicket_voet4'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['OrderTicket_voet4']);}}>OrderTicket_voet4</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['OrderTicket_voet4']);}}>{progVars['OrderTicket_voet4']}</td>
                            </tr>}
                            {progVars['OrderTicket_voet5'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['OrderTicket_voet5']);}}>OrderTicket_voet5</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['OrderTicket_voet5']);}}>{progVars['OrderTicket_voet5']}</td>
                            </tr>}
                            {progVars['OrderTicket_voet6'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['OrderTicket_voet6']);}}>OrderTicket_voet6</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['OrderTicket_voet6']);}}>{progVars['OrderTicket_voet6']}</td>
                            </tr>}
                            {progVars['OrderTicket_voet7'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['OrderTicket_voet7']);}}>OrderTicket_voet7</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['OrderTicket_voet7']);}}>{progVars['OrderTicket_voet7']}</td>
                            </tr>}
                            {progVars['OrderTicket_voet8'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['OrderTicket_voet8']);}}>OrderTicket_voet8</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['OrderTicket_voet8']);}}>{progVars['OrderTicket_voet8']}</td>
                            </tr>}
                            {progVars['OrderTicket_voet9'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['OrderTicket_voet9']);}}>OrderTicket_voet9</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['OrderTicket_voet9']);}}>{progVars['OrderTicket_voet9']}</td>
                            </tr>}
                            {progVars['OrderTicket_voet10'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['OrderTicket_voet10']);}}>OrderTicket_voet10</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['OrderTicket_voet10']);}}>{progVars['OrderTicket_voet10']}</td>
                            </tr>}
                            {progVars['OrderTicket_voet11'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['OrderTicket_voet11']);}}>OrderTicket_voet11</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['OrderTicket_voet11']);}}>{progVars['OrderTicket_voet11']}</td>
                            </tr>}
                            {progVars['OrderTicket_voet12'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['OrderTicket_voet12']);}}>OrderTicket_voet12</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['OrderTicket_voet12']);}}>{progVars['OrderTicket_voet12']}</td>
                            </tr>}
                            {progVars['OrderTicket_voet13'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['OrderTicket_voet13']);}}>OrderTicket_voet13</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['OrderTicket_voet13']);}}>{progVars['OrderTicket_voet13']}</td>
                            </tr>}
                            {progVars['OrderTicket_voet14'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['OrderTicket_voet14']);}}>OrderTicket_voet14</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['OrderTicket_voet14']);}}>{progVars['OrderTicket_voet14']}</td>
                            </tr>}
                            {progVars['OrderTicket_voet15'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['OrderTicket_voet15']);}}>OrderTicket_voet15</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['OrderTicket_voet15']);}}>{progVars['OrderTicket_voet15']}</td>
                            </tr>}
                            {progVars['OrderTicket_voet16'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['OrderTicket_voet16']);}}>OrderTicket_voet16</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['OrderTicket_voet16']);}}>{progVars['OrderTicket_voet16']}</td>
                            </tr>}
                            {progVars['OrderTicket_voet17'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['OrderTicket_voet17']);}}>OrderTicket_voet17</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['OrderTicket_voet17']);}}>{progVars['OrderTicket_voet17']}</td>
                            </tr>}
                            {progVars['OrderTicket_voet18'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['OrderTicket_voet18']);}}>OrderTicket_voet18</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['OrderTicket_voet18']);}}>{progVars['OrderTicket_voet18']}</td>
                            </tr>}
                            {progVars['OrderTicket_voet19'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['OrderTicket_voet19']);}}>OrderTicket_voet19</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['OrderTicket_voet19']);}}>{progVars['OrderTicket_voet19']}</td>
                            </tr>}
                            {progVars['OrderTicket_voet20'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['OrderTicket_voet20']);}}>OrderTicket_voet20</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['OrderTicket_voet20']);}}>{progVars['OrderTicket_voet20']}</td>
                            </tr>}
                        </tbody>
                    </table>
                );
            case "offer":
                return (
                    <table className='_100perc textAlignLeft'>
                        <thead>
                            <tr className='sftBorderBottom'>
                                <th className='sftBorderBottom textAlignLeft width_250px'>
                                    &nbsp; Variable
                                </th>
                                <th className='sftBorderBottom textAlignLeft'>
                                    &nbsp; Value
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {progVars['Offerte_voet'] !== "" && <tr>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Offerte_voet']);}}>Offerte_voet</td>
                                <td onClick={()=>{navigator.clipboard.writeText(progVars['Offerte_voet']);}}>{progVars['Offerte_voet']}</td>
                            </tr>}
                        </tbody>
                    </table>
                );
            default:
                return (<div></div>);
        }
    }

    return (
        <div className='box_fade-in styled-table margin_top_10px'>
            {getInfoTable(props.type)}
        </div>
    );
}

export default ProgVarTable;
