import '../../styles/main.css';
import { React, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { TailSpin } from  'react-loader-spinner'
import Button from '@mui/material/Button';
import MuiPhoneNumber from 'material-ui-phone-number';
import Colors from '../../library/Colors';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';

const ShippingSender = (props) =>  {
    const [nameHelper, setNameHelper] = useState("");  
    const [loading, setLoading] = useState(true); 
    const [streetNameHelper, setStreetNameHelper] = useState("");
    const [houseNumberHelper, setHouseNumberHelper] = useState("");
    const [zipHelper, setZipHelper] = useState("");
    const [cityHelper, setCityHelper] = useState("");
    const [countryHelper, setCountryHelper] = useState("");
    const [emailHelper, setEmailHelper] = useState("");
    const [phoneHelper, setPhoneHelper] = useState("");
    const [name, setName] = useState(props.shipper.name);
    const [company, setCompany] = useState(props.shipper.company);
    const [streetName, setStreetName] = useState(props.shipper.street_name);
    const [houseNumber, setHouseNumber] = useState(props.shipper.house_number);
    const [zip, setZip] = useState(props.shipper.zip);
    const [city, setCity] = useState(props.shipper.city);
    const [country, setCountry] = useState({iso: 'BE', iso3: 'BEL', name: 'België'});
    const [email, setEmail] = useState(props.shipper.email);
    const [phone, setPhone] = useState(props.shipper.phone);
    const [selectedStore, setSelectedStore] = useState(0);

    const handleChange = (event) => {
        try{
            setSelectedStore(event.target.value);
            setName(props.stores[event.target.value].shipping_name);
            setCompany(props.stores[event.target.value].shipping_company);
            setStreetName(props.stores[event.target.value].shipping_street_name);
            setHouseNumber(props.stores[event.target.value].shipping_street_number);
            setZip(props.stores[event.target.value].shipping_zip);
            setCity(props.stores[event.target.value].shipping_city);
            if(props.stores[event.target.value].shipping_country && props.stores[event.target.value].shipping_country !== undefined){
                let countryId   = 0;
                countryId       = getCountry(props.stores[event.target.value].shipping_country);
                
                setCountry(props.countries[countryId]);
            }
            setEmail(props.stores[event.target.value].shipping_email);
            setPhone(props.stores[event.target.value].shipping_phone);
           
        } catch (error){}
    };
   
    useEffect(()=>{
        let shipper             = {name: "", company: "", street_name: "", house_number: "", zip: "", city: "", country: "", email: "", phone: ""};
        if(props.shipper.name !==  shipper.name || props.shipper.company !==  shipper.company || props.shipper.street_name !==  shipper.street_name || props.shipper.house_number !==  shipper.house_number || props.shipper.zip !==  shipper.zip || props.shipper.city !==  shipper.city || props.shipper.country !==  shipper.country || props.shipper.email !==  shipper.email || props.shipper.phone !==  shipper.phone){
            setName(props.shipper.name);
            setCompany(props.shipper.company);
            setStreetName(props.shipper.street_name);
            setHouseNumber(props.shipper.house_number);
            setZip(props.shipper.zip);
            setCity(props.shipper.city);
            let countryId   = 0;
            if(props.shipper.country && props.shipper.country !== undefined){
                
                countryId       = getCountry(props.shipper.country);
                
                setCountry(props.countries[countryId]);
            }
            setEmail(props.shipper.email);
            setPhone(props.shipper.phone);   
            if(props.selectedStore !== undefined && props.receiptNr !== undefined){
                let allowNext2 = true;   
                    if(props.shipper.name !== "" || props.shipper.company !== ""){
                        setNameHelper("");
                    } else {
                        setNameHelper("Firstname, lastname or company is required.");
                        allowNext2 = false;
                    }

                    if(props.shipper.street_name !== ""){
                        setStreetNameHelper("");
                    } else {
                        setStreetNameHelper("Streetname is required.");
                        allowNext2 = false;
                    }

                    if(props.shipper.house_number !== ""){
                        setHouseNumberHelper("");
                    } else {
                        setHouseNumberHelper("Housenumber is required.");
                        allowNext2 = false;
                    }

                    if(props.shipper.zip !== ""){
                        setZipHelper("");
                    } else {
                        setZipHelper("Zip is required.");
                        allowNext2 = false;
                    }

                    if(props.shipper.city !== ""){
                        setCityHelper("");
                    } else {
                        setCityHelper("City is required.");
                        allowNext2 = false;
                    }

                    if(country["iso"] !== ""){
                        setCountryHelper("");
                    } else {
                        setCountryHelper("Country is required.");
                        allowNext2 = false;
                    }

                    if(props.shipper.email !== ""){
                        setEmailHelper("");
                    } else {
                        setEmailHelper("Email is required.");
                        allowNext2 = false;
                    }
                if(allowNext2 === true && props.error === ''){
                    let shipper = {name: props.shipper.name, company: props.shipper.company, street_name: props.shipper.street_name, house_number: props.shipper.house_number, zip: props.shipper.zip, city: props.shipper.city, country: country["iso"], email: props.shipper.email, phone: props.shipper.phone};

                    props.setShipperData(shipper);
                }
                setLoading(false);
            }else {
                setLoading(false);
            }
        } else if(props.stores.length > 0){                        
            setName(props.stores[selectedStore].shipping_name);
            setCompany(props.stores[selectedStore].shipping_company);
            setStreetName(props.stores[selectedStore].shipping_street_name);
            setHouseNumber(props.stores[selectedStore].shipping_street_number);
            setZip(props.stores[selectedStore].shipping_zip);
            setCity(props.stores[selectedStore].shipping_city);
            if(props.stores[selectedStore].shipping_country && props.stores[selectedStore].shipping_country !== undefined){
                let countryId   = 0;
                countryId       = getCountry(props.stores[selectedStore].shipping_country);
                
                setCountry(props.countries[countryId]);
            }
            setEmail(props.stores[selectedStore].shipping_email);
            setPhone(props.stores[selectedStore].shipping_phone);
            if(props.selectedStore !== undefined && props.receiptNr !== undefined){
                let allowNext = true;   
                    if(props.stores[selectedStore].shipping_name !== "" || props.stores[selectedStore].shipping_company !== ""){
                        setNameHelper("");
                    } else {
                        setNameHelper("Firstname, lastname or company is required.");
                        allowNext = false;
                    }

                    if(props.stores[selectedStore].shipping_street_name !== ""){
                        setStreetNameHelper("");
                    } else {
                        setStreetNameHelper("Streetname is required.");
                        allowNext = false;
                    }

                    if(props.stores[selectedStore].shipping_street_number !== ""){
                        setHouseNumberHelper("");
                    } else {
                        setHouseNumberHelper("Housenumber is required.");
                        allowNext = false;
                    }

                    if(props.stores[selectedStore].shipping_zip !== ""){
                        setZipHelper("");
                    } else {
                        setZipHelper("Zip is required.");
                        allowNext = false;
                    }

                    if(props.stores[selectedStore].shipping_city !== ""){
                        setCityHelper("");
                    } else {
                        setCityHelper("City is required.");
                        allowNext = false;
                    }

                    if(country["iso"] !== ""){
                        setCountryHelper("");
                    } else {
                        setCountryHelper("Country is required.");
                        allowNext = false;
                    }

                    if(props.stores[selectedStore].shipping_email !== ""){
                        setEmailHelper("");
                    } else {
                        setEmailHelper("Email is required.");
                        allowNext = false;
                    }
                if(allowNext === true && props.error === ''){
                    let shipper = {name: props.stores[selectedStore].shipping_name, company: props.stores[selectedStore].shipping_company, street_name: props.stores[selectedStore].shipping_street_name, house_number: props.stores[selectedStore].shipping_street_number, zip: props.stores[selectedStore].shipping_zip, city: props.stores[selectedStore].shipping_city, country: country["iso"], email: props.stores[selectedStore].shipping_email, phone: props.stores[selectedStore].shipping_phone};
                    props.setShipperData(shipper);
                    props.switchForm(true);
                }
            }          
            setLoading(false);
        } 
    }, [props.stores, props.showCustomerForm]);

    function showName(){
        let nameLabel = props.t('name');
        let companyLabel = props.t('company');
            return (<div><TextField
            className={(props.theme==='light') ? 'light_accent shipping_width_49perc margin_bottom_10px' : 'dark_accent_darker shipping_width_49perc margin_bottom_10px'}
            id="shipper_name"
            label={(name !== "" ? "" : nameLabel)}
            error={(nameHelper === "" ? false : true)}        
            helperText={nameHelper}  
            sx={(props.theme==='light') ? {
                '& .MuiOutlinedInput-input': {
                    color: Colors.light_text
                  },
                '& .MuiInputLabel-root': {
                    color: Colors.light_text
                }} 
                :
                {
                '& .MuiOutlinedInput-input': {
                    color: Colors.dark_text
                    },
                    '& .MuiInputLabel-root': {
                        color: Colors.dark_text
                    }}}
            value={name}            
            onChange={(event)=>{setName(event.target.value)}}
            />             
            <TextField          
            className={(props.theme==='light') ? 'light_accent shipping_width_50perc shipping_float_right margin_bottom_10px' : 'dark_accent_darker shipping_width_50perc shipping_float_right margin_bottom_10px'}
            id="shipper_company"
            label={(company !== "" ? "" : companyLabel)}
            error={(nameHelper === "" ? false : true)}        
            helperText={nameHelper}  
            sx={(props.theme==='light') ? {
                '& .MuiOutlinedInput-input': {
                    color: Colors.light_text
                  },
                '& .MuiInputLabel-root': {
                    color: Colors.light_text
                }} 
                :
                {
                '& .MuiOutlinedInput-input': {
                    color: Colors.dark_text
                    },
                    '& .MuiInputLabel-root': {
                        color: Colors.dark_text
                    }}}
            value={company}
            onChange={(event)=>{setCompany(event.target.value)}}
            /></div>) ;
    }

    function showStreetName(){
        let streetnameLabel = props.t('streetname');
            return <TextField
            required
            className={(props.theme==='light') ? 'light_accent _100perc margin_bottom_10px' : 'dark_accent_darker _100perc margin_bottom_10px'}
            id="customer_streetname"
            label={(streetName !== "" ? "" : streetnameLabel)}
            error={(streetNameHelper === "" ? false : true)}        
            helperText={streetNameHelper}  
            sx={(props.theme==='light') ? {
                '& .MuiOutlinedInput-input': {
                    color: Colors.light_text
                  },
                '& .MuiInputLabel-root': {
                    color: Colors.light_text
                }} 
                :
                {
                '& .MuiOutlinedInput-input': {
                    color: Colors.dark_text
                    },
                    '& .MuiInputLabel-root': {
                        color: Colors.dark_text
                    }}}
            value={streetName}
            onChange={(event)=>{setStreetName(event.target.value)}}
            /> 
    }

    function showHouseNumber() {
        let housenumberLabel = props.t('housenumber');
            return <TextField
            required
            className={(props.theme==='light') ? 'light_accent width_120px shipping_float_right margin_bottom_10px' : 'dark_accent_darker width_120px shipping_float_right margin_bottom_10px'}
            id="customer_housenumber"
            label={(houseNumber !== "" ? "" : housenumberLabel)}
            error={(houseNumberHelper === "" ? false : true)}        
            helperText={houseNumberHelper} 
            sx={(props.theme==='light') ? {
                '& .MuiOutlinedInput-input': {
                    color: Colors.light_text
                  },
                '& .MuiInputLabel-root': {
                    color: Colors.light_text
                }} 
                :
                {
                '& .MuiOutlinedInput-input': {
                    color: Colors.dark_text
                    },
                    '& .MuiInputLabel-root': {
                        color: Colors.dark_text
                    }}}
            value={houseNumber}
            onChange={(event)=>{setHouseNumber(event.target.value)}}
            />  
    }

    function showZip() {
        let zipLabel = props.t('zip');
            return <TextField
            required
            className={(props.theme==='light') ? 'light_accent shipping_width_49perc ' + props.theme : 'dark_accent_darker shipping_width_49perc ' + props.theme}
            id="customer_zip"
            label={(zip !== "" ? "" : zipLabel)}            
            error={(zipHelper === "" ? false : true)}        
            helperText={zipHelper} 
            sx={(props.theme==='light') ? {
                '& .MuiOutlinedInput-input': {
                    color: Colors.light_text
                  },
                '& .MuiInputLabel-root': {
                    color: Colors.light_text
                }} 
                :
                {
                '& .MuiOutlinedInput-input': {
                    color: Colors.dark_text
                    },
                    '& .MuiInputLabel-root': {
                        color: Colors.dark_text
                    }}}
            value={zip}
            onChange={(event)=>{setZip(event.target.value)}}
            /> 
    }

    function showCity() {
        let cityLabel = props.t('city');
            return <TextField
            required
            className={(props.theme==='light') ? 'light_accent  shipping_width_50perc shipping_float_right margin_bottom_10px' : 'dark_accent_darker  shipping_width_50perc shipping_float_right margin_bottom_10px'}
            id="customer_city"
            label={(city !== "" ? "" : cityLabel)}
            error={(cityHelper === "" ? false : true)}        
            helperText={cityHelper} 
            sx={(props.theme==='light') ? {
                '& .MuiOutlinedInput-input': {
                    color: Colors.light_text
                  },
                '& .MuiInputLabel-root': {
                    color: Colors.light_text
                }} 
                :
                {
                '& .MuiOutlinedInput-input': {
                    color: Colors.dark_text
                    },
                    '& .MuiInputLabel-root': {
                        color: Colors.dark_text
                    }}}
            value={city}
            onChange={(event)=>{setCity(event.target.value)}}
            />    
    }

    function showEmail() {
        let emailLabel = props.t('emailAddress');
            return <TextField
            required
            className={(props.theme==='light') ? 'light_accent shipping_field_full_width' : 'dark_accent_darker shipping_field_full_width'}
            id="customer_email"
            label={(email !== "" ? "" : emailLabel)}            
            error={(emailHelper === "" ? false : true)}        
            helperText={emailHelper} 
            sx={(props.theme==='light') ? {
                '& .MuiOutlinedInput-input': {
                    color: Colors.light_text
                  },
                '& .MuiInputLabel-root': {
                    color: Colors.light_text
                }} 
                :
                {
                '& .MuiOutlinedInput-input': {
                    color: Colors.dark_text
                    },
                    '& .MuiInputLabel-root': {
                        color: Colors.dark_text
                    }}}
            value={email}
            onChange={(event)=>{setEmail(event.target.value)}}
            /> 
    }

    function handleOnPhoneChange(value) {
        setPhone(value);
    }

    function showPhoneNumber(){
            return <MuiPhoneNumber 
            className={(props.theme==='light') ? 'light_accent shipping_field_full_width' : 'dark_accent_darker shipping_field_full_width'} 
            defaultCountry={'be'} 
            value={phone}                        
            error={(phoneHelper === "" ? false : true)}        
            helperText={phoneHelper} 
            sx={(props.theme==='light') ? {
                '& .MuiInput-input': {
                    color: Colors.light_text
                  }} 
                :
                  {
                    '& .MuiInput-input': {
                        color: Colors.dark_text
                      }}}
            onChange={handleOnPhoneChange} />;
    }
    
    function validateData(){   
        //console.log("Housenumber: ", houseNumber);  
        let allowNext = true;   
        if(validationTest(name, /^([A-z])/) || validationTest(company, /^([A-z])/)){
            setNameHelper("");
        } else {
            setNameHelper(props.t('nameHelperText'));
            allowNext = false;
        }

        if(validationTest(streetName, /^([A-z])/)){
            setStreetNameHelper("");
        } else {
            setStreetNameHelper(props.t('streetnameHelperText'));
            allowNext = false;
        }

        if(validationTest(houseNumber, /^([0-9])/)){
            setHouseNumberHelper("");
        } else {
            setHouseNumberHelper(props.t('housenumberHelperText'));
            allowNext = false;
        }

        if(validationTest(zip, /([A-Za-z0-9])/)){
            setZipHelper("");
        } else {
            setZipHelper(props.t('zipHelperText'));
            allowNext = false;
        }

        if(validationTest(city, /^([A-z])/)){
            setCityHelper("");
        } else {
            setCityHelper(props.t('cityHelperText'));
            allowNext = false;
        }

        if(validationTest(country["iso"], /^([A-z]{2})$/)){
            setCountryHelper("");
        } else {
            setCountryHelper(props.t('countryHelperText'));
            allowNext = false;
        }

        if(validationTest(email, /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)){
            setEmailHelper("");
        } else {
            setEmailHelper(props.t('emailHelperText'));
            allowNext = false;
        }

        setPhoneHelper("");
        

        if(allowNext === true){
            let shipper = {name: name, company: company, street_name: streetName, house_number: houseNumber, zip: zip, city: city, country: country["iso"], email: email, phone: phone};
            props.setShipperData(shipper);
            props.switchForm(true);
        }
    }

    const displayShippingStore = props.stores.map((store, index) => {
            return (
                <MenuItem key={index} value={index}>{store.oms}</MenuItem>
            );
    });
    
    function validationTest(data, validationString) 
    {
        if(data === undefined)
            return false;

        if (validationString.test(data))
        {
            return true;
        } else {
            return false;
        }
    }

    function getCountry(countryIso){
        if(props.countries.length > 1){
            for (let i = 0; i < props.countries.length; i++) {
                if(props.countries[i]["iso"] === countryIso)
                    return i;
              }
        }
    }

  return (
      <div style={{padding: 5}}>
          {<div className="container_list__title">
                    {props.screenWidth >= 600 && <Button variant="contained" className={'button_container__button width_140px align_left'} onClick={()=>{props.switchForm(false);}}>{props.t('back')}</Button>}
                    {props.screenWidth < 600 && <h2 className={(props.theme==='dark') ? "dark " : "light margin_bottom_none "} style={{textAlign: 'center', width: '100%'}}>{props.t('titleShippingSender')}</h2>}
                    {props.screenWidth >= 600 && <h2 className={(props.theme==='dark') ? "dark container_list__title  detail" : "light container_list__title margin_bottom_none detail"} type="text" id="list_name" name="listName">{props.t('titleShippingSender')}</h2>}
                    {props.screenWidth < 600 && <div className="margin_bottom_10px"></div>}
                    {props.screenWidth < 600 && <Button variant="contained" className={'button_container__button width_140px align_left'} onClick={()=>{props.switchForm(false);}}>{props.t('back')}</Button>}
                    <Button variant="contained" className={'button_container__button width_140px align_right'} onClick={()=>{validateData();}}>{props.t('next')}</Button>
                </div>
            }   
        {loading && <div className={"container_list__title " + props.theme} style={{display:"flex", justifyContent: "space-evenly", marginTop: 5, border: 1}}>
            <TailSpin 
                height="40"
                width="40"
                color={Colors.sft_blue}
                ariaLabel='loading'
            />
        </div>}
        {!loading && <div className='_100perc margin_bottom_15px'>  
            {props.stores.length > 0 && <Select            
            id="shipment-senderStore"
            value={selectedStore}
            onChange={handleChange}
            className={(props.theme==='light') ? 'light_accent shipping_field_full_width' : 'dark_accent_darker shipping_field_full_width'} 
                sx={(props.theme==='light') ? {
                    '& .MuiInputLabel-root ': {
                        color: Colors.light_text                        
                      },
                    '& .MuiSelect-outlined ': {
                        height: '18px',
                        padding: '8px 14px'
                    },
                    '& .MuiSelect-nativeInput ': {
                        color: Colors.light_text,
                        padddingLeft: '0px'
                        }
                    } 
                    :
                    {
                    '& .MuiInputLabel-root ': {
                        color: Colors.dark_text
                        },
                    '& .MuiSelect-outlined ': {
                        height: '18px',
                        padding: '8px 14px'
                    },
                    '& .MuiSelect-nativeInput ': {
                        color: Colors.dark_text,
                        padddingLeft: '0px'
                        }  
                    }}
            >
            {(props.stores.length > 0) ? displayShippingStore : ''}
            </Select>}
                {showName()}
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    {showStreetName()}  
                    {showHouseNumber()}  
                </div>
                {showZip()}  
                {showCity()}
                {props.countries.length > 0 && <Autocomplete
                id="country"
                className={(props.theme==='light') ? 'light_accent shipping_field_full_width shipping_country' : 'dark_accent_darker shipping_field_full_width shipping_country'} 
                sx={(props.theme==='light') ? {                    
                    '& .MuiInputLabel-root ': {
                        color: Colors.light_text
                      },
                    '& .MuiOutlinedInput-input ': {
                        color: Colors.light_text,
                        height: '28px',
                        padddingLeft: '0px'
                        }
                    } 
                    :
                    {
                    '& .MuiInputLabel-root ': {
                        color: Colors.dark_text
                        },
                    '& .MuiOutlinedInput-input ': {
                        color: Colors.dark_text,
                        height: '28px',
                        padddingLeft: '0px'
                        }  
                    }}


                    MuiInputBase-root-MuiOutlinedInput-root


                options={props.countries}
                autoHighlight
                getOptionLabel={(option) => option.name}
                helperText={countryHelper}
                value={country}
                isOptionEqualToValue = {(option, value) => value.iso === option.iso}               
                onChange={(event, newValue)=>{setCountry(newValue);}}
                renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${option.iso.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${option.iso.toLowerCase()}.png 2x`}
                        alt=""
                        border="1px"
                    />
                    {option.name} ({option.iso})
                    </Box>
                )}
                renderInput={(params) => (
                    <TextField
                    {...params}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                    />
                )}
                />}
                {showEmail()}
                {showPhoneNumber()}
                
        </div>}
    </div>
  );
}

export default ShippingSender;
