import React, { useEffect, useState } from 'react';
import { TailSpin } from  'react-loader-spinner';
import { 
    Link,
    useNavigate,
    useParams
} from "react-router-dom";
import axios from 'axios';
import SftGlobalData from '../library/SftGlobalData';
import Colors from '../library/Colors';
import Button from '@mui/material/Button';
import { TextField } from '@mui/material';
import FloatingBackButton from '../components/FloatingBackButton';
import FloatingSaveButton from '../components/FloatingSaveButton';


const MailIntegrationDetailScreen = (props) => {
    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [name, setName] = useState(params.id);
    const [token, setToken] = useState("");
    const [dailyRepId, setDailyRepId] = useState(0);
    const [fullRepId, setFullRepId] = useState(0);
    const [active, setActive] = useState(false);

    let navigate = useNavigate();
    

    useEffect(() => {
        if(props.api !== ""){
            if(params.id !== '+'){
                let url = SftGlobalData.baseURL_API + 'mailIntegration&action=fetchOne';
                let postData = {key: props.api, id: params.id};
                axios.post(url, postData, {
                    headers : {
                        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                    }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log("URL", url);
                        console.log("POST", postData);
                        console.log("RESP", response);
                    }
                    setToken(response.data.api);
                    setDailyRepId(response.data.reportId);
                    setFullRepId(response.data.reportIdFull);
                    setName(response.data.remark);
                    setActive(response.data.active === "1");
                    
                    setLoading(false);
                });
            } else {
                setLoading(false);
            }
        }
    },[props.api, params.id]);

    const submitHandler = async () => {
        let url = SftGlobalData.baseURL_API_old + '?key='+props.api+'&create=1&mailchimp=default';
        axios.get(url)
            .then(response => {
                //console.log(response);
                if(response.data === "OK"){                    
                    props.notify(props.t('defaultCreated'), 7500, "top-right", props.theme, 1);   
                } else {                    
                    props.notify(props.t('errorOccured'), 7500, "top-right", props.theme, 1);   
                }
            });
      }

    const changeActive = async (event) => {
        setActive(!active);
    }

    const changeMailCredentials = () => {
        let url = SftGlobalData.baseURL_API + 'mailIntegration&action=update';
        let postData = {
            key: props.api, 
            id: params.id,
            token: token,
            daily: dailyRepId,
            full: fullRepId,
            active: active ? '1' : '0'
        };
        axios.post(url, postData, {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            }})
        .then(response => {
            if(SftGlobalData.debug){
                console.log("URL", url);
                console.log("POST", postData);
                console.log("RESP", response);
            }
            props.notify(props.t('savedChanges'), 7500, "top-right", props.theme, 1);   
        });
    }

    return (
        <div className={(props.screenWidth) < 600 ? 'not_expanded container_content _80perc ' + props.theme + ' container_content_' + props.theme : 'expanded container_content _80perc ' + props.theme + ' container_content_' + props.theme} >            
            <FloatingBackButton disabled={false} onClick={()=>{navigate("/"+props.lang+"/mailchimp");}} />
            <FloatingSaveButton disabled={loading} onClick={()=>{changeMailCredentials();}} />
            
            {loading && <div className={"container_list__title " + props.theme} style={{display:"flex", justifyContent: "space-evenly", marginTop: 5, border: 1}}>
                <TailSpin 
                    height="40"
                    width="40"
                    color={Colors.sft_blue}
                    ariaLabel='loading'
                />
            </div>}

            {!loading && (params.id !== '+') &&
                    <form>
                        <div className={"container_list__title " + props.theme}><h2>Mailchimp - {name}</h2></div>
                        <legend className='_100perc'>
                            Mailchimp
                            <label className="switch align_right">
                                <input type="checkbox" checked={active} onChange={changeActive}/>
                                <span className={"slider slider_" + props.theme + " round"}></span>
                            </label>
                        </legend>
                        
                        <TextField
                            minRows={1}
                            autoComplete="off" 
                            className={props.theme+ '_accent _100perc margin_top_10px'}
                            id="token"
                            label={"Token"}
                            size={'small'}
                            sx={(props.theme==='light') ? {
                                '& .MuiOutlinedInput-input': {
                                    color: Colors.light_text
                                  },
                                '& .MuiInputLabel-root': {
                                    color: Colors.light_text_accent
                                 }, borderRadius: 3, marginTop: 0} 
                                :
                                {
                                '& .MuiOutlinedInput-input': {
                                    color: Colors.dark_text
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: Colors.dark_text_accent
                                    }, borderRadius: 3, marginTop: 0}}
                            value={token}
                            onChange={(event)=>{setToken(event.target.value)}}
                            />  
                        <div className='container_split'>
                            <TextField
                                minRows={1}
                                autoComplete="off" 
                                className={props.theme+ '_accent _100perc margin_top_10px margin_right__5px'}
                                id="dailySyncRepId"
                                label={"Daily sync report ID"}
                                size={'small'}
                                sx={(props.theme==='light') ? {
                                    '& .MuiOutlinedInput-input': {
                                        color: Colors.light_text
                                      },
                                    '& .MuiInputLabel-root': {
                                        color: Colors.light_text_accent
                                     }, borderRadius: 3, marginTop: 0} 
                                    :
                                    {
                                    '& .MuiOutlinedInput-input': {
                                        color: Colors.dark_text
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: Colors.dark_text_accent
                                        }, borderRadius: 3, marginTop: 0}}
                                value={dailyRepId}
                                onChange={(event)=>{setDailyRepId(event.target.value)}}
                                />
                            
                            <TextField
                                minRows={1}
                                autoComplete="off" 
                                className={props.theme+ '_accent _100perc margin_top_10px'}
                                id="fullSyncRepId"
                                label={"Full sync report ID"}
                                size={'small'}
                                sx={(props.theme==='light') ? {
                                    '& .MuiOutlinedInput-input': {
                                        color: Colors.light_text
                                      },
                                    '& .MuiInputLabel-root': {
                                        color: Colors.light_text_accent
                                     }, borderRadius: 3, marginTop: 0} 
                                    :
                                    {
                                    '& .MuiOutlinedInput-input': {
                                        color: Colors.dark_text
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: Colors.dark_text_accent
                                        }, borderRadius: 3, marginTop: 0}}
                                value={fullRepId}
                                onChange={(event)=>{setFullRepId(event.target.value)}}
                                />
                        </div>
                        <br/>

                        <Button className="button_container__button _100perc" type="submit">{props.t('save')}</Button>
                    </form>
            }

            {!loading && (params.id === '+') &&
                <form>
                    <div className={"container_list__title " + props.theme}><h2>New mail integration</h2></div>
                    
                    <Button type="button" className="button_container__button _100perc" disabled={params.id !== '+'} onClick={()=>{submitHandler()}}>&nbsp;{props.t('createDefault')}&nbsp;</Button>
                </form>
        }
            <br/><br/>            
        </div>
    );
}

export default MailIntegrationDetailScreen;
