import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TailSpin } from 'react-loader-spinner';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Button, IconButton, InputAdornment, OutlinedInput, Switch, TextField } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { HexColorPicker } from "react-colorful";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import EmailEditor from 'react-email-editor';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTiktok, faXTwitter, faWhatsapp, faInstagram, faFacebook, faYoutube, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faShoppingCart, faGlobe, faEnvelope, faLock} from '@fortawesome/free-solid-svg-icons';

// Libraries
import SftGlobalData from '../../library/SftGlobalData';
import Colors from '../../library/Colors';
import './eticket.css';
import './steps.scss';
import ProgVarTable from './ProgVarTable';
import FloatingSaveButton from '../../components/FloatingSaveButton';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import FloatingBackButton from '../../components/FloatingBackButton';


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  

const EticketDetailScreen = (props) =>  {  
    const [loading, setLoading] = useState(true);
    const [value, setValue] = useState(0);
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);   
    const [storeId, setStoreId] = useState("00");
    const [posId, setPosId] = useState("0000"); 
    const [disableControls, setDisableControls] = useState(false);
    const params = useParams();

    // First step
    const [template, setTemplate] = useState('2');
    const [font, setFont] = useState('Montserrat');
    const [color, setColor] = useState('#28A9E0');
    const [textColor, setTextColor] = useState('#FFFFFF');
    const [discountColor, setDiscountColor] = useState('#FF5454');
    const [discountTextColor, setDiscountTextColor] = useState('#FFFFFF');
    const [templates, setTemplates] = useState([]);

    // Second step
    const [header, setHeader] = useState("");
    const [footer, setFooter] = useState("[kasticket_hoofd1] \n[kasticket_hoofd2] \n[kasticket_hoofd3] \n[kasticket_hoofd4] \n[kasticket_hoofd5] \n[kasticket_hoofd6] \n[kasticket_hoofd7] \n");
    const [progVars, setProgVars] = useState({});
    const [selectedHeaderTag, setSelectedHeaderTag] = useState("");
    const [selectedFooterTag, setSelectedFooterTag] = useState("");
    const [selectedHeaderTagValue, setSelectedHeaderTagValue] = useState("");
    const [selectedFooterTagValue, setSelectedFooterTagValue] = useState("");
    const [headerReservation, setHeaderReservation] = useState("");
    const [footerReservation, setFooterReservation] = useState("[kasticket_hoofd1] \n[kasticket_hoofd2] \n[kasticket_hoofd3] \n[kasticket_hoofd4] \n[kasticket_hoofd5] \n[kasticket_hoofd6] \n[kasticket_hoofd7] \n");
    const [selectedHeaderTagReservation, setSelectedHeaderTagReservation] = useState("");
    const [selectedFooterTagReservation, setSelectedFooterTagReservation] = useState("");
    const [selectedHeaderTagValueReservation, setSelectedHeaderTagValueReservation] = useState("");
    const [selectedFooterTagValueReservation, setSelectedFooterTagValueReservation] = useState("");

    // Third step
    const [article, setArticle] = useState({});
    const [detailUseImages, setDetailUseImages] = useState(true);
    const [detailLine1, setDetailLine1] = useState("[CODE_2_DESCRIPTION] [SUPPLIER_NAME] ");
    const [detailLine2, setDetailLine2] = useState("[ARTICLE_DETAIL_1] / [ARTICLE_COLOR_SUPPLIER] [COLOR_DESCRIPTION] ");
    const [detailLine3, setDetailLine3] = useState("Maat: [ARTICLE_SIZE_X_DESCRIPTION] [ARTICLE_SIZE_Y_DESCRIPTION] ");

    // Fourth step
    const [focusTarget, setFocusTarget] = useState("");
    const [disclaimerAdvance, setDisclaimerAdvance] = useState("");
    const [disclaimerCommercialVoucher, setDisclaimerCommercialVoucher] = useState("");
    const [disclaimerGiftVoucher, setDisclaimerGiftVoucher] = useState("");
    const [disclaimerRetouch, setDisclaimerRetouch] = useState("");
    const [disclaimerVoucher, setDisclaimerVoucher] = useState("");
    const [disclaimerReservation, setDisclaimerReservation] = useState("");
    const [disclaimerReceipt, setDisclaimerReceipt] = useState("");
    const [disclaimerCustomerOrder, setDisclaimerCustomerOrder] = useState("");
    const [disclaimerCustomerCardVoucher, setDisclaimerCustomerCardVoucher] = useState("");
    const [disclaimerOffer, setDisclaimerOffer] = useState("");

    // Fifth step
    const [logo, setLogo] = useState("");
    const [logoOverwrite, setLogoOverwrite] = useState(false);

    // Sixth step
    const [urlFacebook, setUrlFacebook] = useState("");
    const [urlInstagram, setUrlInstagram] = useState("");
    const [urlTiktok, setUrlTiktok] = useState("");
    const [urlYoutube, setUrlYoutube] = useState("");
    const [urlTwitter, setUrlTwitter] = useState("");
    const [urlWebshop, setUrlWebshop] = useState("");
    const [urlWebsite, setUrlWebsite] = useState("");
    const [urlWhatsapp, setUrlWhatsapp] = useState("");
    const [urlEmail, setUrlEmail] = useState("");
    const [urlLinkedin, setUrlLinkedin] = useState("");

    // Seventh step
    const [smtpOptions, setSmtpOptions] = useState([]);
    const [smtpPreset, setSmtpPreset] = useState("-1");
    const [smtpPresetId, setSmtpPresetId] = useState("-1");
    const [smtpName, setSmtpName] = useState("");
    const [smtpHost, setSmtpHost] = useState("");
    const [smtpUser, setSmtpUser] = useState("");
    const [smtpPass, setSmtpPass] = useState("");
    const [smtpPort, setSmtpPort] = useState("");
    const [smtpSSL, setSmtpSSL] = useState(false);
    const [mailFromName, setMailFromName] = useState("");
    const [mailFromEmail, setMailFromEmail] = useState("");
    const [mailSubject, setMailSubject] = useState("Bedankt voor uw [TRANSACTION_TYPE]!");
    const [mailErrorEmail, setMailErrorEmail] = useState("");
    const [mailBccEmail, setMailBccEmail] = useState("");
    const [mailBccReservation, setMailBccReservation] = useState("");
    const [mailBccReceipt, setMailBccReceipt] = useState("");
    const [mailBccCustOrder, setMailBccCustOrder] = useState("");
    const [mailBccOffer, setMailBccOffer] = useState("");
    const [validateSmtpLoader, setValidateSmtpLoader] = useState(false);

    // Layout
    const [design, setDesign] = useState("");
    const [html, setHtml] = useState("");
    const emailEditor = useRef(null);
    const [applyTemplate, setApplyTemplate] = useState('2');
    const [applyTemplateLoading, setApplyTemplateLoading] = useState(false);

    let navigate = useNavigate();

    useEffect(() => {
        props.changeScreen('Eticket');
        fetchHeaders()
     },[props.api]);

    const fetchHeaders = () => {
        let url = SftGlobalData.baseURL_API + 'eticket&action=fetch';
        let postData = {
            sft_id: props.login.sft_status,
            sft_group: props.login.sft_group,
            login: props.login.login,
            key: props.api,
            id: params.listId
        }
        axios.post(url, postData, {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            }})
            .then(response => {
                if(SftGlobalData.debug){
                    console.log('url', url);
                    console.log('postData', postData);
                    console.log('response', response);
                }
                
                
                setStoreId(response.data.settings[params.elementId].store_id);
                setPosId(response.data.settings[params.elementId].pos_id);

                setTemplate(response.data.settings[params.elementId].layout.template_id);
                setFont(response.data.settings[params.elementId].layout.font);
                setColor(response.data.settings[params.elementId].layout.accent_color);
                setTextColor(response.data.settings[params.elementId].layout.accent_text_color);
                setDiscountColor(response.data.settings[params.elementId].layout.discount_color);
                setDiscountTextColor(response.data.settings[params.elementId].layout.discount_text_color);
                try{setDesign(response.data.settings[params.elementId].layout.design);
                    try{
                        if(response.data.settings[params.elementId].layout.design){
                            emailEditor.current.loadDesign(response.data.settings[params.elementId].layout.design);
                        }
                    }catch(e){
                }}catch(e){}
                setHtml(response.data.settings[params.elementId].layout.html);

                setHeader(response.data.settings[params.elementId].program_vars.eticket_header);
                setFooter(response.data.settings[params.elementId].program_vars.eticket_footer);
                setHeaderReservation(response.data.settings[params.elementId].program_vars.eticket_header_reservation);
                setFooterReservation(response.data.settings[params.elementId].program_vars.eticket_footer_reservation);
                
                setDetailUseImages(response.data.settings[params.elementId].program_vars.eticket_use_images === "T");
                setDetailLine1(response.data.settings[params.elementId].program_vars.eticket_detail1);
                setDetailLine2(response.data.settings[params.elementId].program_vars.eticket_detail2);
                setDetailLine3(response.data.settings[params.elementId].program_vars.eticket_detail3);

                setDisclaimerAdvance(response.data.settings[params.elementId].program_vars.eticket_disclaimer_advance);
                setDisclaimerCommercialVoucher(response.data.settings[params.elementId].program_vars.eticket_disclaimer_comm_voucher);
                setDisclaimerGiftVoucher(response.data.settings[params.elementId].program_vars.eticket_disclaimer_gift_voucher);
                setDisclaimerRetouch(response.data.settings[params.elementId].program_vars.eticket_disclaimer_retouch);
                setDisclaimerVoucher(response.data.settings[params.elementId].program_vars.eticket_disclaimer_voucher);
                setDisclaimerReservation(response.data.settings[params.elementId].program_vars.eticket_disclaimer_reservation);
                setDisclaimerReceipt(response.data.settings[params.elementId].program_vars.eticket_disclaimer_receipt);
                setDisclaimerCustomerOrder(response.data.settings[params.elementId].program_vars.eticket_disclaimer_cust_order);
                setDisclaimerCustomerCardVoucher(response.data.settings[params.elementId].program_vars.eticket_disclaimer_cc_voucher);
                setDisclaimerOffer(response.data.settings[params.elementId].program_vars.eticket_disclaimer_offer);
             
                setLogo("data:image/jpeg;base64,"+response.data.settings[params.elementId].program_vars.ticket_logo);

                setUrlFacebook(response.data.settings[params.elementId].links.facebook);
                setUrlInstagram(response.data.settings[params.elementId].links.instagram);
                setUrlTiktok(response.data.settings[params.elementId].links.tiktok);
                setUrlYoutube(response.data.settings[params.elementId].links.youtube);
                setUrlTwitter(response.data.settings[params.elementId].links.twitter);
                setUrlWebshop(response.data.settings[params.elementId].links.webshop);
                setUrlWebsite(response.data.settings[params.elementId].links.website);
                setUrlWhatsapp(response.data.settings[params.elementId].links.whatsapp);
                setUrlEmail(response.data.settings[params.elementId].links.email);
                setUrlLinkedin(response.data.settings[params.elementId].links.linkedIn);

                
                setSmtpPresetId(response.data.settings[params.elementId].smtp.preset);
                
                setSmtpName(response.data.settings[params.elementId].smtp.name);
                setSmtpHost(response.data.settings[params.elementId].smtp.host);
                setSmtpUser(response.data.settings[params.elementId].smtp.user);
                setSmtpPass(response.data.settings[params.elementId].smtp.pass);
                setSmtpPort(response.data.settings[params.elementId].smtp?.port);
                setSmtpSSL(response.data.settings[params.elementId].smtp.SSL);
                setMailFromName(response.data.settings[params.elementId].mail_settings.sender_name);
                setMailFromEmail(response.data.settings[params.elementId].mail_settings.sender_mail);
                setMailSubject(response.data.settings[params.elementId].mail_settings.subject);
                setMailErrorEmail(response.data.settings[params.elementId].mail_settings.error_mail);
                setMailBccEmail(response.data.settings[params.elementId].mail_settings.bcc_mail);
                setMailBccReservation(response.data.settings[params.elementId].mail_settings.bcc_mail_reservation);
                setMailBccReceipt(response.data.settings[params.elementId].mail_settings.bcc_mail_receipt);
                setMailBccCustOrder(response.data.settings[params.elementId].mail_settings.bcc_mail_cust_order);
                setMailBccOffer(response.data.settings[params.elementId].mail_settings.bcc_mail_offer);

                setName(response.data.settings[params.elementId].name);
                fetchRequiredData(response.data.settings[params.elementId].store_id, response.data.settings[params.elementId].pos_id, response.data.settings[params.elementId].smtp.preset);

            });
    }

    const fetchRequiredData = (store_id, pos_id, smtppresetid) => {
        let url = SftGlobalData.baseURL_API + 'eticket&action=loadVars';
        let postData = {
            sft_id: props.login.sft_status,
            sft_group: props.login.sft_group,
            login: props.login.login,
            key: props.api,
            store_id: store_id,
            pos_id: pos_id
        }
        axios.post(url, postData, {
        headers : {
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
        }})
        .then(response => {
            if(SftGlobalData.debug){
                console.log('url', url);
                console.log('response', response);
            }
            setProgVars(response.data);
            let url2 = SftGlobalData.baseURL_API + 'templates&action=fetchHeaders';
            let postData2 = {
                sft_id: props.login.sft_status,
                sft_group: props.login.sft_group,
                login: props.login.login,
                key: props.api,
                type: "ETICKET",
                active: 1,
                store_id: store_id,
                pos_id: pos_id
            }
            axios.post(url2, postData2, {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            }})
            .then(resp => {
                if(SftGlobalData.debug){
                    console.log('url', url2);
                    console.log('response', resp);
                }
                setTemplates(resp.data);
                let url3 = SftGlobalData.baseURL_API+'client&action=fetchSchedulerSMTP';
                let postData3 = {key: props.api}
                axios.post(url3, postData3, {
                    headers : {
                        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                    }})
                    .then(respo => {
                        if(SftGlobalData.debug){
                            console.log('url', url3);
                            console.log('postData', postData3);
                            console.log('response', respo);
                        }
                        setSmtpOptions(respo.data.servers);
                        for(let i = 0; i < respo.data.servers.length; i++){
                            if(respo.data.servers[i].smtpid === smtppresetid){
                                setSmtpPreset(i+"");
                            }
                        }
                        let url4 = SftGlobalData.baseURL_API+'eticket&action=loadArticle';
                        let postData4 = {
                            sft_id: props.login.sft_status,
                            sft_group: props.login.sft_group,
                            login: props.login.login,
                            key: props.api,
                            store_id: store_id,
                            pos_id: pos_id
                        }
                        axios.post(url4, postData4, {
                            headers : {
                                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                            }})
                            .then(respon => {
                                if(SftGlobalData.debug){
                                    console.log('url', url4);
                                    console.log('postData', postData4);
                                    console.log('response', respon);
                                }
                                setArticle(respon.data);
                                setLoading(false);
                            });
                        //fetchMySftLogo();
                    });
            });
        });
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
        exportHtml(false);
    };

    const exportHtml = (doSave) => {
        let design_temp  = "";
        let html_temp    = "";
        try{
            emailEditor.current.editor.exportHtml((data) => {
                const { design, html } = data;
                setHtml(html);
                setDesign(design);
                design_temp = design;
                html_temp = html;
                if(doSave)
                    save(design_temp, html_temp);
            });
        }catch(e){
            if(doSave){
                if(template === "0")
                    save(design, html)
                else 
                    save("", "")
            }
                
        }
    }
    
    const save = (des, htm) => {
        let url = SftGlobalData.baseURL_API + 'eticket&action=wizard';
        let postData = {
            sft_group: props.login.sft_group,
            sft_status: props.login.sft_status,
            sft_user_id: props.login.sft_user_id,
            login: props.login.login,
            key: props.api,
            store_id: storeId,
            pos_id: posId,

            id: params.listId,
            element: params.elementId,

            settings: [{
                store_id: storeId,
                pos_id: posId,
                smtp: {
                        preset: smtpPresetId,
                        name: smtpName,
                        host: smtpHost,
                        user: smtpUser,
                        pass: smtpPass,
                        port: smtpPort,
                        SSL: smtpSSL
                    },
                mail_settings: {
                        sender_mail: mailFromEmail,
                        sender_name: mailFromName,
                        subject: mailSubject,
                        error_mail: mailErrorEmail,
                        bcc_mail: mailBccEmail,
                        bcc_mail_receipt: mailBccReceipt,
                        bcc_mail_reservation: mailBccReservation,
                        bcc_mail_cust_order: mailBccCustOrder,
                        bcc_mail_offer: mailBccOffer
                    },
                layout: {
                        template_id: template,
                        design: des,
                        html: htm,
                        accent_color: color,
                        accent_text_color: textColor,
                        discount_color: discountColor,
                        discount_text_color: discountTextColor,
                        font: font
                    },
                query_settings: {
                        custom_joins: "",
                        custom_select: ""
                    },
                links: {
                        website: urlWebsite,
                        webshop: urlWebshop,
                        facebook: urlFacebook,
                        instagram: urlInstagram,
                        tiktok: urlTiktok,
                        twitter: urlTwitter,
                        linkedIn: urlLinkedin,
                        youtube: urlYoutube, 
                        email: urlEmail,
                        whatsapp: urlWhatsapp,
                        other: ""
                    }
            }],
            eticket_header: header, 
            eticket_footer: footer, 
            eticket_header_reservation: headerReservation, 
            eticket_footer_reservation: footerReservation, 
            eticket_detail1: detailLine1, 
            eticket_detail2: detailLine2, 
            eticket_detail3: detailLine3, 
            eticket_use_images: detailUseImages, 
            eticket_disclaimer_advance: disclaimerAdvance, 
            eticket_disclaimer_retouch: disclaimerRetouch, 
            eticket_disclaimer_voucher: disclaimerVoucher, 
            eticket_disclaimer_gift_voucher: disclaimerGiftVoucher,
            eticket_disclaimer_comm_voucher: disclaimerCommercialVoucher,
            eticket_disclaimer_cc_voucher: disclaimerCustomerCardVoucher,
            eticket_disclaimer_receipt: disclaimerReceipt,
            eticket_disclaimer_reservation: disclaimerReservation,
            eticket_disclaimer_cust_order: disclaimerCustomerOrder,
            eticket_disclaimer_offer: disclaimerOffer,
            ticket_logo: logo ,
        }
        axios.post(url, postData, {
        headers : {
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
        }})
        .then(response => {
            if(SftGlobalData.debug){
                console.log('url', url);
                console.log('postData', postData);
                console.log('response', response);
            }
            setDisableControls(false);
            props.notify(props.t('savedChanges'), 7500, "top-right", props.theme, 1); 
            // TODO: Error handling
        });
    
    }

    const checkPw = (pw) => {
        if(pw === SftGlobalData.superUltraSecretLockPassword){
            console.log("SUPER SECRET PASSWORD UNLOCK");
            setTemplate("0");
            try{
                if(design){
                    emailEditor.current.loadDesign(design);
                }
            }catch(e){}
        }
    }

    const onLoad = () => {
        // editor instance is created
        // you can load your template here;
        // const templateJson = {};
        // emailEditorRef.current.editor.loadDesign(templateJson);
    }

    const onReady = () => {
        // editor is ready
        //console.log('design', designNL);
        try{
            if(design){
                emailEditor.current.loadDesign(design);
            }
        }catch(e){
            console.error(e);
        }
    }
    
/*************************************************************************************
*
*                             TEMPLATE / PERSONALISATION
*
*************************************************************************************/

const onSelectSmtpOption = (option) => {
    if(option === "-1"){
        setSmtpName("");
        setSmtpHost("");
        setSmtpUser("");
        setSmtpPass("");
        setSmtpPort("");
        setSmtpSSL(false);
        setSmtpPresetId("0");
    } else {
        setSmtpName(smtpOptions[option].smtpname);
        setSmtpHost(smtpOptions[option].smtphost);
        setSmtpUser("**********");
        setSmtpPass("**********");
        setSmtpPort(smtpOptions[option].smtpport);
        setSmtpSSL(smtpOptions[option].sslStartMode);
        setSmtpPresetId(smtpOptions[option].smtpid);
        //setMailFrom(smtpOptions[option].frommail);
    }
}

const fetchMySftLogo = () => {
    let urlLogo = SftGlobalData.baseURL_API+'client&action=fetchLogo';
    let postDataLogo = {key: props.api}
    axios.post(urlLogo, postDataLogo, {
        headers : {
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
        }})
        .then(responseLogo => {
            if(SftGlobalData.debug){
                console.log('url', urlLogo);
                console.log('postData', urlLogo);
                console.log('response', responseLogo);
            }
            setLogo(responseLogo.data);
            setLogoOverwrite(false);
        });
}

const onLogoChange = (e) => {
    let files = e.target.files;
    let fileReader = new FileReader();
    fileReader.readAsDataURL(files[0]);

    fileReader.onload = (event) => {
        if(SftGlobalData.debug){
            console.log("logo", event.target.result);
        }
        setLogo(event.target.result);
        setLogoOverwrite(true);
    }
}

const onSaveLogo = async () => {
    try{
        let url = SftGlobalData.baseURL_API+'client&action=updateLogo';
        let postData = {
            key: props.api,
            logo: logo, user: props.username, sftUserId: props.userId
        }
        axios.post(url, postData, {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            }})
            .then(response => {
                if(SftGlobalData.debug){
                    console.log('url', url);
                    console.log('postData', postData);
                    console.log('resp', response);
                }
                props.notify(props.t('savedChanges'), 7500, "top-right", props.theme, 1); 
                setLogoOverwrite(false);
            });
    } catch(e){
        console.error(e);
        props.notify(props.t('errorOccured'), 7500, "top-right", props.theme, 1); 
    }
}

const fetchArticleTags = () => {
    let arr = [];
    for(let x in article){
        if(x !== "ARTICLE_PHOTO")
            arr.push(x);
    }
    return arr.map((a) => (
        <tr key={a}  onClick={()=>{navigator.clipboard.writeText("["+a+"] ");}}>
            <td onClick={()=>{navigator.clipboard.writeText("["+a+"] ");}}>{a}</td>
            <td onClick={()=>{navigator.clipboard.writeText("["+a+"] ");}}>{article[a]}</td>
        </tr>
    ));

}

const fetchProgVarOptions = () => {
    let arr = [];
    for(let x in progVars){
        arr.push(x);
    }
    arr.push("CUST_FIRST_NAME");
    arr.push("CUST_LAST_NAME");
    arr.push("CUST_TITLE");
    arr.push("STORE_DESCRIPTION");
    arr.push("STORE_SHIPPING_NAME");
    arr.push("STORE_SHIPPING_COMPANY");
    arr.push("STORE_SHIPPING_STREET");
    arr.push("STORE_SHIPPING_STREET_NUMBER");
    arr.push("STORE_SHIPPING_ZIP");
    arr.push("STORE_SHIPPING_CITY");
    arr.push("STORE_SHIPPING_COUNTRY");
    arr.push("STORE_SHIPPING_EMAIL");
    arr.push("STORE_SHIPPING_PHONE");
    arr.push("TRANSACTION_ID");
    arr.push("TRANSACTION_TYPE");
    arr.push("CUST_ID");
    arr.push("DATE");
    arr.push("HOUR");
    arr.push("SEAL");
    arr.push("USER_NAME");
    arr.push("POS_DESCRIPTION");
    //console.log("progVars", progVars);
    //console.log("arr", arr);
    return arr.map((a) => (
        <MenuItem
        value={a}
        >
            {a}
        </MenuItem>
    ));

}

const getTagsPreview = (text) => {
    let resp = text;

    try{resp = resp.replaceAll('[Kasticket_hoofd]', progVars['Kasticket_hoofd']);}catch(exception){console.error(exception);}
    try{resp = resp.replaceAll('[Kasticket_voet]', progVars['Kasticket_voet']);}catch(exception){console.error(exception);}
    resp = resp.replaceAll('[B]', '<b>');
    resp = resp.replaceAll('[/B]', '</b>');
    resp = resp.replaceAll('[AlignCenter]', '<center>');
    resp = resp.replaceAll('[/AlignCenter]', '</center>');
    resp = resp.replaceAll('[TR]', '');
    resp = resp.replaceAll('[/TR]', '');
    resp = resp.replaceAll('[ReverseColor]', '');
    resp = resp.replaceAll('[/ReverseColor]', '');
    resp = resp.replaceAll('[CharacterWidth2]', '');
    resp = resp.replaceAll('[/CharacterWidth2]', '');
    resp = resp.replaceAll('[CharacterHeight2]', '');
    resp = resp.replaceAll('[/CharacterHeight2]', '');
    resp = resp.replaceAll('[CharacterWidthHeight2]', '');
    resp = resp.replaceAll('[/CharacterWidthHeight2]', '');
    resp = resp.replaceAll('[CharacterNormal]', '');
    resp = resp.replaceAll('[/CharacterNormal]', '');
    resp = resp.replaceAll('[CharacterBig]', '');
    resp = resp.replaceAll('[/CharacterBig]', '');
    resp = resp.replaceAll('[CharacterLarge]', '');
    resp = resp.replaceAll('[/CharacterLarge]', '');
    resp = resp.replaceAll('[AlignLeft]', '');
    resp = resp.replaceAll('[/AlignLeft]', '');
    resp = resp.replaceAll('[AlignRight]', '');
    resp = resp.replaceAll('[/AlignRight]', '');
    resp = resp.replaceAll('[Line1]', '<hr/>');
    resp = resp.replaceAll('[Line2]', '<hr/>');
    resp = resp.replaceAll('[Line3]', '<hr/>');
    resp = resp.replaceAll('[Line4]', '<hr/>');
    resp = resp.replaceAll('[Line5]', '<hr/>');
    resp = resp.replaceAll('[Line6]', '<hr/>');
    resp = resp.replaceAll('[ReceiptHeader1]', '[kasticket_hoofd1]');
    resp = resp.replaceAll('[ReceiptHeader2]', '[kasticket_hoofd2]');
    resp = resp.replaceAll('[ReceiptHeader3]', '[kasticket_hoofd3]');
    resp = resp.replaceAll('[ReceiptHeader4]', '[kasticket_hoofd4]');
    resp = resp.replaceAll('[ReceiptHeader5]', '[kasticket_hoofd5]');
    resp = resp.replaceAll('[ReceiptHeader6]', '[kasticket_hoofd6]');
    resp = resp.replaceAll('[ReceiptHeader7]', '[kasticket_hoofd7]');
    resp = resp.replaceAll('[ReceiptFooter1]', '[kasticket_voet1]');
    resp = resp.replaceAll('[ReceiptFooter2]', '[kasticket_voet2]');
    resp = resp.replaceAll('[ReceiptFooter3]', '[kasticket_voet3]');
    resp = resp.replaceAll('[ReceiptFooter4]', '[kasticket_voet4]');
    resp = resp.replaceAll('[ReceiptFooter5]', '[kasticket_voet5]');
    resp = resp.replaceAll('[ReceiptFooter6]', '[kasticket_voet6]');
    resp = resp.replaceAll('[ReceiptFooter7]', '[kasticket_voet7]');
    resp = resp.replaceAll('[ReceiptFooter8]', '[kasticket_voet8]');
    resp = resp.replaceAll('[ReceiptFooter9]', '[kasticket_voet9]');
    resp = resp.replaceAll('[ReceiptFooter10]', '[kasticket_voet10]');
    resp = resp.replaceAll('[ReceiptFooter11]', '[kasticket_voet11]');
    resp = resp.replaceAll('[ReceiptFooter12]', '[kasticket_voet10]');
    resp = resp.replaceAll('[ReceiptFooter13]', '[kasticket_voet13]');
    resp = resp.replaceAll('[ReceiptFooter14]', '[kasticket_voet14]');
    resp = resp.replaceAll('[ReceiptFooter15]', '[kasticket_voet15]');
    resp = resp.replaceAll('[ReceiptFooter16]', '[kasticket_voet16]');
    resp = resp.replaceAll('[ReceiptFooter17]', '[kasticket_voet17]');
    resp = resp.replaceAll('[ReceiptFooter18]', '[kasticket_voet18]');
    resp = resp.replaceAll('[ReceiptFooter19]', '[kasticket_voet19]');
    resp = resp.replaceAll('[ReceiptFooter20]', '[kasticket_voet20]');

    for(let x in progVars){
        //console.log('['+x+']', progVars[x]);
        resp = resp.replaceAll('['+x+']', progVars[x]);
    }
    return resp;
}

const getArticlePreviewLine = (text) => {
    let resp = text;

    for(let x in article){
        resp = resp.replaceAll('['+x+']', article[x]);
    }
    return resp;
}

const addTagToHeader = (tag) => {
    if(tag !== ""){
        setHeader(header + "[" + tag+"] ");
    }
}

const addTagToFooter = (tag) => {
    if(tag !== ""){
        setFooter(footer + "[" + tag+"] ");
    }
}

const addTagToHeaderReservation = (tag) => {
    if(tag !== ""){
        setHeaderReservation(headerReservation + "[" + tag+"] ");
    }
}

const addTagToFooterReservation = (tag) => {
    if(tag !== ""){
        setFooterReservation(footerReservation + "[" + tag+"] ");
    }
}

const firstStep = () => {
    return (
        <div>
            <div className="rowContainerEvenly" style={{width: '100%'}} >
                <div className='margin_top_10px columnContainerBetween slide-in-blurred-left' style={{width: '25%'}}>
                    <FormControl className={props.theme+ '_accent _100perc'}>
                      <InputLabel id="demo-simple-select-label">Template</InputLabel>
                      <Select
                        className={props.theme+"_accent "+props.theme+"_text _100perc"}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={template}
                        label="Template"
                        onChange={(event)=>{const {target: { value },} = event; setTemplate(value);}}
                        style={{height: 40, paddingTop: 8, paddingBottom: 8}}>
                            {(password === SftGlobalData.superUltraSecretLockPassword || template === "0") && 
                            <MenuItem
                                key={0}
                                value={0}
                                >
                                    CUSTOM
                                </MenuItem>}
                        {templates.map((template) => (
                            <MenuItem
                            key={template.id}
                            value={template.id}
                            >
                                {template.title}
                            </MenuItem>
                        ))}
                        
                      </Select>
                    </FormControl>
                    <FormControl className={props.theme+ '_accent _100perc'}>
                      <InputLabel id="demo-simple-select-label">Font</InputLabel>
                      <Select
                        className={props.theme+"_accent "+props.theme+"_text _100perc"}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={font}
                        label="Font"
                        onChange={(event)=>{const {target: { value },} = event; setFont(value);}}
                        style={{height: 40, paddingTop: 8, paddingBottom: 8}}>
                        <MenuItem value={"Arial"}>Arial</MenuItem>
                        <MenuItem value={"Brush Script MT"}>Brush Script MT</MenuItem>
                        <MenuItem value={"Comic Sans MS"}>Comic Sans MS</MenuItem>
                        <MenuItem value={"Courier New"}>Courier New</MenuItem>
                        <MenuItem value={"Georgia"}>Georgia</MenuItem>
                        <MenuItem value={"Garamond"}>Garamond</MenuItem>
                        <MenuItem value={"Montserrat"}>Montserrat</MenuItem>
                        <MenuItem value={"Open Sans"}>Open Sans</MenuItem>
                        <MenuItem value={"'Times New Roman'"}>Times New Roman</MenuItem>
                        <MenuItem value={"Verdana"}>Verdana</MenuItem>
                      </Select>
                    </FormControl>
                    <p style={{fontFamily: font, textAlign: 'center', backgroundColor: color, color: textColor, width: '100%', marginBottom: 1}}> <br/> Example text <br/> &nbsp;</p>
                </div>
                <div className='margin_top_10px columnContainerBetween slide-in-blurred-top' style={{width: '25%'}}>
                    <TextField
                        minRows={1}
                        autoComplete="off" 
                        className={props.theme+ '_accent _100perc'}
                        id="accent_color"
                        label={"Accent color"}
                        size={'small'}
                        sx={(props.theme==='light') ? {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.light_text
                              },
                            '& .MuiInputLabel-root': {
                                color: Colors.light_text_accent
                             }, borderRadius: 3, marginTop: 0} 
                            :
                            {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.dark_text
                                },
                                '& .MuiInputLabel-root': {
                                    color: Colors.dark_text_accent
                                }, borderRadius: 3, marginTop: 0}}
                        value={color.toUpperCase()}
                        onChange={(event)=>{setColor(event.target.value)}}
                        required
                        />  
                    <section className="resposive example margin_top_15px">
                        <HexColorPicker className="colorpicker" color={color} onChange={(event)=>{setColor(event);}} />
                    </section>
                </div>
                <div className='margin_top_10px columnContainerBetween slide-in-blurred-right' style={{width: '25%'}}>
                    <TextField
                        minRows={1}
                        autoComplete="off" 
                        className={props.theme+ '_accent _100perc'}
                        id="text_color"
                        label={"Text color"}
                        size={'small'}
                        sx={(props.theme==='light') ? {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.light_text
                              },
                            '& .MuiInputLabel-root': {
                                color: Colors.light_text_accent
                             }, borderRadius: 3, marginTop: 0} 
                            :
                            {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.dark_text
                                },
                                '& .MuiInputLabel-root': {
                                    color: Colors.dark_text_accent
                                }, borderRadius: 3, marginTop: 0}}
                        value={textColor.toUpperCase()}
                        onChange={(event)=>{setTextColor(event.target.value)}}
                        required
                        />  
                    <section className="resposive example margin_top_15px">
                        <HexColorPicker className="colorpicker" color={textColor} onChange={(event)=>{setTextColor(event);}} />
                    </section>
                </div>
            </div>
            <div className="rowContainerEvenly" style={{width: '100%', marginTop: 10}} >
                <div className='margin_top_10px columnContainerBetween slide-in-blurred-right' style={{width: '25%', alignItems: 'bottom'}}>
                    <p style={{fontFamily: font, textAlign: 'left', backgroundColor: discountColor, color: discountTextColor, width: '100%', marginTop: 'auto', marginBottom: 3, paddingTop: 5, paddingBottom: 5}}> &nbsp;&nbsp;&nbsp;<b>Solden:      € 10.00 - € 2.00 </b> &nbsp;</p>
                </div>
                <div className='margin_top_10px columnContainerBetween slide-in-blurred-left' style={{width: '25%'}}>
                    <TextField
                        minRows={1}
                        autoComplete="off" 
                        className={props.theme+ '_accent _100perc'}
                        id="accent_color_discount"
                        label={"Accent color discount"}
                        size={'small'}
                        sx={(props.theme==='light') ? {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.light_text
                              },
                            '& .MuiInputLabel-root': {
                                color: Colors.light_text
                             }, borderRadius: 3, marginTop: 0} 
                            :
                            {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.dark_text
                                },
                                '& .MuiInputLabel-root': {
                                    color: Colors.dark_text_accent
                                }, borderRadius: 3, marginTop: 0}}
                        value={discountColor.toUpperCase()}
                        onChange={(event)=>{setDiscountColor(event.target.value)}}
                        required
                        />  
                    <section className="resposive example margin_top_15px">
                        <HexColorPicker className="colorpicker" color={discountColor} onChange={(event)=>{setDiscountColor(event);}} />
                    </section>
                </div>
                <div className='margin_top_10px columnContainerBetween slide-in-blurred-top' style={{width: '25%'}}>
                    <TextField
                        minRows={1}
                        autoComplete="off" 
                        className={props.theme+ '_accent _100perc'}
                        id="text_color_discount"
                        label={"Text color discount"}
                        size={'small'}
                        sx={(props.theme==='light') ? {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.light_text
                              },
                            '& .MuiInputLabel-root': {
                                color: Colors.light_text
                             }, borderRadius: 3, marginTop: 0} 
                            :
                            {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.dark_text
                                },
                                '& .MuiInputLabel-root': {
                                    color: Colors.dark_text_accent
                                }, borderRadius: 3, marginTop: 0}}
                        value={discountTextColor.toUpperCase()}
                        onChange={(event)=>{setDiscountTextColor(event.target.value)}}
                        required
                        />  
                    <section className="resposive example margin_top_15px">
                        <HexColorPicker className="colorpicker" color={discountTextColor} onChange={(event)=>{setDiscountTextColor(event);}} />
                    </section>
                </div>
                    
               </div>
            </div>
    )
}

/*************************************************************************************
*
*                                HEADER & FOOTER
*
*************************************************************************************/
const secondStep = () => {
    return (
        <div>
        <div className='rowContainerBetween'>
            <div className="_45perc slide-in-blurred-left">
                <h4>Header</h4>
                <div className="rowContainerStart">
                    <textarea className={"textareaBorderRight "+ ((props.theme==='light') ? 'light_accent_darker textarea' : 'dark_accent textarea')} id="memo" onChange={(event)=>{setHeader(event.target.value)}} name="header" value={header} style={{resize: 'none', minHeight: 245}}/>
                    <textarea className={"textareaBorderLeft "+ ((props.theme==='light') ? 'light_accent_darkest textarea' : 'dark_accent_darker textarea')} id="memo" disabled name="header_preview" value={getTagsPreview(header)} style={{resize: 'none', minHeight: 245}}/>
                </div>
                <div className="rowContainerBetween margin_bottom_10px" style={{alignItems: 'center'}}>
                    <FormControl className={props.theme+ '_accent _80perc'}>
                      <InputLabel id="demo-simple-select-label">Tags</InputLabel>
                      <Select
                        className={props.theme+"_accent "+props.theme+"_text _100perc"}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedHeaderTag}
                        label="Tags"
                        onChange={(event)=>{const {target: { value },} = event; setSelectedHeaderTag(value); try{setSelectedHeaderTagValue(progVars[value]);}catch(exception){console.error(exception);}}}
                        style={{height: 40, paddingTop: 8, paddingBottom: 8}}>
                        {fetchProgVarOptions()}
                      </Select>
                    </FormControl>
                    <Button type="button" className={"button_container__button_no_margin width_120px maxHeight "} onClick={()=>{addTagToHeader(selectedHeaderTag)}}>&nbsp;{props.t('add')}&nbsp;</Button>
                </div>
                <p>{selectedHeaderTagValue}</p>
            </div>
            <div className="_45perc slide-in-blurred-right">
                <h4>Footer</h4>
                <div className="rowContainerEnd">
                    <textarea className={"textareaBorderRight "+ ((props.theme==='light') ? 'light_accent_darker textarea' : 'dark_accent textarea')} id="memo" onChange={(event)=>{setFooter(event.target.value)}} name="footer" value={footer} style={{resize: 'none', minHeight: 245}}/>
                    <textarea className={"textareaBorderLeft "+ ((props.theme==='light') ? 'light_accent_darkest textarea' : 'dark_accent_darker textarea')} id="memo" disabled name="footer_preview" value={getTagsPreview(footer)} style={{resize: 'none', minHeight: 245}}/>
                </div>
                <div className="rowContainerBetween margin_bottom_10px" style={{alignItems: 'center'}}>
                    <FormControl className={props.theme+ '_accent _80perc'}>
                      <InputLabel id="demo-simple-select-label">Tags</InputLabel>
                      <Select
                        className={props.theme+"_accent "+props.theme+"_text _100perc"}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedFooterTag}
                        label="Tags"
                        onChange={(event)=>{const {target: { value },} = event; setSelectedFooterTag(value);  try{setSelectedFooterTagValue(progVars[value]);}catch(exception){console.error(exception);}}}
                        style={{height: 40, paddingTop: 8, paddingBottom: 8}}>
                        {fetchProgVarOptions()}
                      </Select>
                    </FormControl>
                    <Button type="button" className={"button_container__button_no_margin width_120px maxHeight "} onClick={()=>{addTagToFooter(selectedFooterTag)}}>&nbsp;{props.t('add')}&nbsp;</Button>
                </div>
                <p>{selectedFooterTagValue}</p>
            </div>
        </div>
        <div className='rowContainerBetween'>
            <div className="_45perc slide-in-blurred-left">
                <h4>Header (Reservation)</h4>
                <div className="rowContainerStart">
                    <textarea className={"textareaBorderRight "+ ((props.theme==='light') ? 'light_accent_darker textarea' : 'dark_accent textarea')} id="memo" onChange={(event)=>{setHeaderReservation(event.target.value)}} name="headerReservation" value={headerReservation} style={{resize: 'none', minHeight: 245}}/>
                    <textarea className={"textareaBorderLeft "+ ((props.theme==='light') ? 'light_accent_darkest textarea' : 'dark_accent_darker textarea')} id="memo" disabled name="header_preview" value={getTagsPreview(headerReservation)} style={{resize: 'none', minHeight: 245}}/>
                </div>
                <div className="rowContainerBetween margin_bottom_10px" style={{alignItems: 'center'}}>
                    <FormControl className={props.theme+ '_accent _80perc'}>
                      <InputLabel id="demo-simple-select-label">Tags</InputLabel>
                      <Select
                        className={props.theme+"_accent "+props.theme+"_text _100perc"}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedHeaderTagReservation}
                        label="Tags"
                        onChange={(event)=>{const {target: { value },} = event; setSelectedHeaderTagReservation(value); try{setSelectedHeaderTagValueReservation(progVars[value]);}catch(exception){console.error(exception);}}}
                        style={{height: 40, paddingTop: 8, paddingBottom: 8}}>
                        {fetchProgVarOptions()}
                      </Select>
                    </FormControl>
                    <Button type="button" className={"button_container__button_no_margin width_120px maxHeight "} onClick={()=>{addTagToHeaderReservation(selectedHeaderTagReservation)}}>&nbsp;{props.t('add')}&nbsp;</Button>
                </div>
                <p>{selectedHeaderTagValueReservation}</p>
            </div>
            <div className="_45perc slide-in-blurred-right">
                <h4>Footer (Reservation)</h4>
                <div className="rowContainerEnd">
                    <textarea className={"textareaBorderRight "+ ((props.theme==='light') ? 'light_accent_darker textarea' : 'dark_accent textarea')} id="memo" onChange={(event)=>{setFooterReservation(event.target.value)}} name="footer" value={footerReservation} style={{resize: 'none', minHeight: 245}}/>
                    <textarea className={"textareaBorderLeft "+ ((props.theme==='light') ? 'light_accent_darkest textarea' : 'dark_accent_darker textarea')} id="memo" disabled name="footer_preview" value={getTagsPreview(footerReservation)} style={{resize: 'none', minHeight: 245}}/>
                </div>
                <div className="rowContainerBetween margin_bottom_10px" style={{alignItems: 'center'}}>
                    <FormControl className={props.theme+ '_accent _80perc'}>
                      <InputLabel id="demo-simple-select-label">Tags</InputLabel>
                      <Select
                        className={props.theme+"_accent "+props.theme+"_text _100perc"}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedFooterTagReservation}
                        label="Tags"
                        onChange={(event)=>{const {target: { value },} = event; setSelectedFooterTagReservation(value);  try{setSelectedFooterTagValueReservation(progVars[value]);}catch(exception){console.error(exception);}}}
                        style={{height: 40, paddingTop: 8, paddingBottom: 8}}>
                        {fetchProgVarOptions()}
                      </Select>
                    </FormControl>
                    <Button type="button" className={"button_container__button_no_margin width_120px maxHeight "} onClick={()=>{addTagToFooterReservation(selectedFooterTagReservation)}}>&nbsp;{props.t('add')}&nbsp;</Button>
                </div>
                <p>{selectedFooterTagValueReservation}</p>
            </div>
        </div>
    </div>
    );
}

/*************************************************************************************
*
*                                   DETAIL
*
*************************************************************************************/
const thirdStep = () => {
    return (
        <div className='rowContainerBetween'>
            <div className={"_50perc slide-in-blurred-left"}>
            <TextField
                    minRows={1}
                    autoComplete="off" 
                    inputProps={{ maxLength: 255 }}
                    className={props.theme+ '_accent _100perc margin_top_10px'}
                    id="detail_line1"
                    label={"Detail line 1"}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={detailLine1}
                    onChange={(event)=>{setDetailLine1(event.target.value)}}
                    />  
                <TextField
                    minRows={1}
                    autoComplete="off" 
                    inputProps={{ maxLength: 255 }}
                    className={props.theme+ '_accent _100perc margin_top_10px'}
                    id="detail_line2"
                    label={"Detail line 2"}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={detailLine2}
                    onChange={(event)=>{setDetailLine2(event.target.value)}}
                    /> 
                <TextField
                    minRows={1}
                    autoComplete="off" 
                    inputProps={{ maxLength: 255 }}
                    className={props.theme+ '_accent _100perc margin_top_10px'}
                    id="detail_line3"
                    label={"Detail line 3"}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={detailLine3}
                    onChange={(event)=>{setDetailLine3(event.target.value)}}
                    />  
                <div className='rowContainerBetween alignCenterInContainer'>
                    <div style={{marginLeft: 5}}>Use images</div>
                    <Switch
                      checked={detailUseImages}
                      onChange={(event)=>{setDetailUseImages(event.target.checked)}}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                </div>

                <div className='margin_top_15px rowContainerStart sftBorder'>
                    {detailUseImages && <div className='columnContainerCenter'><img alt="Article" src={"data:image/jpeg;base64,"+article["ARTICLE_PHOTO"]} style={{width: 100, height: 100, objectFit: 'contain'}}/></div>}
                    <div className='columnContainerCenter margin_left__10px'>
                        <div><b>{getArticlePreviewLine(detailLine1)}</b></div>
                        <div className='margin_top_5px'>{getArticlePreviewLine(detailLine2)}</div>
                        <div className='margin_top_5px'>{getArticlePreviewLine(detailLine3)}</div>
                    </div>

                </div>
            </div>
            <div className={"_45perc columnContainerStart"}>
                <div className='slide-in-blurred-right styled-table margin_top_10px'>
                    <table className='_100perc textAlignLeft '>
                        <thead>
                            <tr className='sftBorderBottom'>
                                <th className='sftBorderBottom textAlignLeft width_250px'>&nbsp; Tag</th>
                                <th className='sftBorderBottom textAlignLeft'>&nbsp; Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            {fetchArticleTags()}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

/*************************************************************************************
*
*                                   DISCLAIMER
*
*************************************************************************************/
const fourthStep = () => {
    return (
        <div className='rowContainerBetween'>
            <div className={"_50perc"}>
                <TextField 
                    minRows={1}
                    autoComplete="off" 
                    inputProps={{ maxLength: 1024 }}
                    className={props.theme+ '_accent _100perc margin_top_10px scale-up-center'}
                    id="advance"
                    label={"Disclaimer advance"}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={disclaimerAdvance}
                    onChange={(event)=>{setDisclaimerAdvance(event.target.value)}}
                    onFocus={()=>{setFocusTarget("advance")}}
                />  
                <TextField 
                    minRows={1}
                    autoComplete="off" 
                    inputProps={{ maxLength: 1024 }}
                    className={props.theme+ '_accent _100perc margin_top_10px scale-up-center-2'}
                    id="retouch"
                    label={"Disclaimer retouch"}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={disclaimerRetouch}
                    onChange={(event)=>{setDisclaimerRetouch(event.target.value)}}
                    onFocus={()=>{setFocusTarget("retouch")}}
                />  

                <TextField 
                    minRows={1}
                    autoComplete="off" 
                    inputProps={{ maxLength: 1024 }}
                    className={props.theme+ '_accent _100perc margin_top_10px scale-up-center-3'}
                    id="voucher"
                    label={"Disclaimer voucher"}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={disclaimerVoucher}
                    onChange={(event)=>{setDisclaimerVoucher(event.target.value)}}
                    onFocus={()=>{setFocusTarget("voucher")}}
                />  
                <TextField 
                    minRows={1}
                    autoComplete="off" 
                    inputProps={{ maxLength: 1024 }}
                    className={props.theme+ '_accent _100perc margin_top_10px scale-up-center-4'}
                    id="giftvoucher"
                    label={"Disclaimer gift voucher"}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={disclaimerGiftVoucher}
                    onChange={(event)=>{setDisclaimerGiftVoucher(event.target.value)}}
                    onFocus={()=>{setFocusTarget("giftvoucher")}}
                />  
                <TextField 
                    minRows={1}
                    autoComplete="off" 
                    inputProps={{ maxLength: 1024 }}
                    className={props.theme+ '_accent _100perc margin_top_10px scale-up-center-5'}
                    id="commvoucher"
                    label={"Disclaimer commercial voucher"}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={disclaimerCommercialVoucher}
                    onChange={(event)=>{setDisclaimerCommercialVoucher(event.target.value)}}
                    onFocus={()=>{setFocusTarget("commercialvoucher")}}
                />  
                <TextField 
                    minRows={1}
                    autoComplete="off" 
                    inputProps={{ maxLength: 1024 }}
                    className={props.theme+ '_accent _100perc margin_top_10px scale-up-center-6'}
                    id="ccvoucher"
                    label={"Disclaimer customer card voucher"}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={disclaimerCustomerCardVoucher}
                    onChange={(event)=>{setDisclaimerCustomerCardVoucher(event.target.value)}}
                    onFocus={()=>{setFocusTarget("customercardvoucher")}}
                />  
                <TextField 
                    minRows={1}
                    autoComplete="off" 
                    inputProps={{ maxLength: 1024 }}
                    className={props.theme+ '_accent _100perc margin_top_10px scale-up-center-7'}
                    id="receipt"
                    label={"Disclaimer receipt"}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={disclaimerReceipt}
                    onChange={(event)=>{setDisclaimerReceipt(event.target.value)}}
                    onFocus={()=>{setFocusTarget("receipt")}}
                />  
                <TextField 
                    minRows={1}
                    autoComplete="off" 
                    inputProps={{ maxLength: 1024 }}
                    className={props.theme+ '_accent _100perc margin_top_10px scale-up-center-8'}
                    id="reservation"
                    label={"Disclaimer reservation"}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={disclaimerReservation}
                    onChange={(event)=>{setDisclaimerReservation(event.target.value)}}
                    onFocus={()=>{setFocusTarget("reservation")}}
                />  
                <TextField 
                    minRows={1}
                    autoComplete="off" 
                    inputProps={{ maxLength: 1024 }}
                    className={props.theme+ '_accent _100perc margin_top_10px scale-up-center-9'}
                    id="offer"
                    label={"Disclaimer offer"}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={disclaimerOffer}
                    onChange={(event)=>{setDisclaimerOffer(event.target.value)}}
                    onFocus={()=>{setFocusTarget("offer")}}
                />  
                <TextField 
                    minRows={1}
                    autoComplete="off" 
                    inputProps={{ maxLength: 1024 }}
                    className={props.theme+ '_accent _100perc margin_top_10px scale-up-center-10'}
                    id="custorder"
                    label={"Disclaimer customer order"}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={disclaimerCustomerOrder}
                    onChange={(event)=>{setDisclaimerCustomerOrder(event.target.value)}}
                    onFocus={()=>{setFocusTarget("customerorder")}}
                />  
            </div>
            <div className={"_45perc columnContainerStart"}>
                {focusTarget !== "" && <ProgVarTable type={focusTarget} progVars={progVars} />}
            </div>
            
        </div>
    );
}

/*************************************************************************************
*
*                                   LOGO
*
*************************************************************************************/
const fifthStep = () => {
    return (
        <div className='rowContainerCenter'>
            <div className='_50perc slide-in-blurred-top'>
                <div className='flex_center_column'>
                    <br/>
                    <img alt="Customer logo" src={logo} style={{width: 300, height: 100, objectFit: 'contain'}}/>
                </div>
                <br/>
                <label className="text-white">Select File</label>
                <input type="file" className="form-control" name="image" onChange={onLogoChange}  accept="image/jpeg, image/png, image/jpg"/>
                <Button className={"modal_button_container_no_margin__button margin_top_10px _100perc"} onClick={()=>{fetchMySftLogo()}}>Get from licence</Button>
                <Button className={"modal_button_container_no_margin__button margin_top_10px _100perc"} onClick={()=>{onSaveLogo();}} disabled={!logoOverwrite}>Save to licence</Button>
            </div>
        </div>
    );
}

/*************************************************************************************
*
*                                   URLS
*
*************************************************************************************/
const sixthStep = () => {
    return (
        <div>
            <TextField 
                minRows={1}
                autoComplete="off" 
                className={props.theme+ '_accent _100perc scale-up-center'}
                id="facebook"
                label={"Facebook"}
                size={'small'}
                InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" sx={{ width: 20 }}>
                        <FontAwesomeIcon icon={faFacebook} size="lg" />
                      </InputAdornment>
                    ),
                  }}
                sx={(props.theme==='light') ? {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.light_text
                      },
                    '& .MuiInputLabel-root': {
                        color: Colors.light_text
                     }, borderRadius: 3, marginTop: 0} 
                    :
                    {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.dark_text
                        },
                        '& .MuiInputLabel-root': {
                            color: Colors.dark_text_accent
                        }, borderRadius: 3, marginTop: 0}}
                value={urlFacebook}
                onChange={(event)=>{setUrlFacebook(event.target.value)}}
            />  
            <TextField 
                minRows={1}
                autoComplete="off" 
                className={props.theme+ '_accent _100perc margin_top_10px scale-up-center-2'}
                id="instagram"
                label={"Instagram"}
                size={'small'}
                InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" sx={{ width: 20 }}>
                        <FontAwesomeIcon icon={faInstagram} size="lg" />
                      </InputAdornment>
                    ),
                  }}
                sx={(props.theme==='light') ? {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.light_text
                      },
                    '& .MuiInputLabel-root': {
                        color: Colors.light_text
                     }, borderRadius: 3, marginTop: 0} 
                    :
                    {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.dark_text
                        },
                        '& .MuiInputLabel-root': {
                            color: Colors.dark_text_accent
                        }, borderRadius: 3, marginTop: 0}}
                value={urlInstagram}
                onChange={(event)=>{setUrlInstagram(event.target.value)}}
            />  
            <TextField 
                minRows={1}
                autoComplete="off" 
                className={props.theme+ '_accent _100perc margin_top_10px scale-up-center-3'}
                id="tiktok"
                label={"TikTok"}
                size={'small'}
                InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" sx={{ width: 20 }}>
                        <FontAwesomeIcon icon={faTiktok} size="lg" />
                      </InputAdornment>
                    ),
                  }}
                sx={(props.theme==='light') ? {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.light_text
                      },
                    '& .MuiInputLabel-root': {
                        color: Colors.light_text
                     }, borderRadius: 3, marginTop: 0} 
                    :
                    {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.dark_text
                        },
                        '& .MuiInputLabel-root': {
                            color: Colors.dark_text_accent
                        }, borderRadius: 3, marginTop: 0}}
                value={urlTiktok}
                onChange={(event)=>{setUrlTiktok(event.target.value)}}
            />  
            <TextField 
                minRows={1}
                autoComplete="off" 
                className={props.theme+ '_accent _100perc margin_top_10px scale-up-center-3'}
                id="twitter"
                label={"Twitter / X"}
                size={'small'}
                InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" sx={{ width: 20 }}>
                        <FontAwesomeIcon icon={faXTwitter} size="lg" />
                      </InputAdornment>
                    ),
                  }}
                sx={(props.theme==='light') ? {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.light_text
                      },
                    '& .MuiInputLabel-root': {
                        color: Colors.light_text
                     }, borderRadius: 3, marginTop: 0} 
                    :
                    {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.dark_text
                        },
                        '& .MuiInputLabel-root': {
                            color: Colors.dark_text_accent
                        }, borderRadius: 3, marginTop: 0}}
                value={urlTwitter}
                onChange={(event)=>{setUrlTwitter(event.target.value)}}
            />  
            <TextField 
                minRows={1}
                autoComplete="off" 
                className={props.theme+ '_accent _100perc margin_top_10px scale-up-center-4'}
                id="webshop"
                label={"Webshop"}
                size={'small'}
                InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" sx={{ width: 20 }}>
                        <FontAwesomeIcon icon={faShoppingCart} size="lg" />
                      </InputAdornment>
                    ),
                  }}
                sx={(props.theme==='light') ? {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.light_text
                      },
                    '& .MuiInputLabel-root': {
                        color: Colors.light_text
                     }, borderRadius: 3, marginTop: 0} 
                    :
                    {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.dark_text
                        },
                        '& .MuiInputLabel-root': {
                            color: Colors.dark_text_accent
                        }, borderRadius: 3, marginTop: 0}}
                value={urlWebshop}
                onChange={(event)=>{setUrlWebshop(event.target.value)}}
            />  
            <TextField 
                minRows={1}
                autoComplete="off" 
                className={props.theme+ '_accent _100perc margin_top_10px scale-up-center-5'}
                id="website"
                label={"Website"}
                size={'small'}
                InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" sx={{ width: 20 }}>
                        <FontAwesomeIcon icon={faGlobe} size="lg" />
                      </InputAdornment>
                    ),
                  }}
                sx={(props.theme==='light') ? {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.light_text
                      },
                    '& .MuiInputLabel-root': {
                        color: Colors.light_text
                     }, borderRadius: 3, marginTop: 0} 
                    :
                    {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.dark_text
                        },
                        '& .MuiInputLabel-root': {
                            color: Colors.dark_text_accent
                        }, borderRadius: 3, marginTop: 0}}
                value={urlWebsite}
                onChange={(event)=>{setUrlWebsite(event.target.value)}}
            />  
            <TextField 
                minRows={1}
                autoComplete="off" 
                className={props.theme+ '_accent _100perc margin_top_10px scale-up-center-6'}
                id="youtube"
                label={"Youtube"}
                size={'small'}
                InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" sx={{ width: 20 }}>
                        <FontAwesomeIcon icon={faYoutube} size="lg" />
                      </InputAdornment>
                    ),
                  }}
                sx={(props.theme==='light') ? {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.light_text
                      },
                    '& .MuiInputLabel-root': {
                        color: Colors.light_text
                     }, borderRadius: 3, marginTop: 0} 
                    :
                    {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.dark_text
                        },
                        '& .MuiInputLabel-root': {
                            color: Colors.dark_text_accent
                        }, borderRadius: 3, marginTop: 0}}
                value={urlYoutube}
                onChange={(event)=>{setUrlYoutube(event.target.value)}}
            />  
            <TextField 
                minRows={1}
                autoComplete="off" 
                className={props.theme+ '_accent _100perc margin_top_10px scale-up-center-7'}
                id="linkedin"
                label={"LinkedIn"}
                size={'small'}
                InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" sx={{ width: 20 }}>
                        <FontAwesomeIcon icon={faLinkedin} size="lg" />
                      </InputAdornment>
                    ),
                  }}
                sx={(props.theme==='light') ? {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.light_text
                      },
                    '& .MuiInputLabel-root': {
                        color: Colors.light_text
                     }, borderRadius: 3, marginTop: 0} 
                    :
                    {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.dark_text
                        },
                        '& .MuiInputLabel-root': {
                            color: Colors.dark_text_accent
                        }, borderRadius: 3, marginTop: 0}}
                value={urlLinkedin}
                onChange={(event)=>{setUrlLinkedin(event.target.value)}}
            />  
            <TextField 
                minRows={1}
                autoComplete="off" 
                className={props.theme+ '_accent _100perc margin_top_10px scale-up-center-8'}
                id="email"
                label={"Email"}
                size={'small'}
                InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" sx={{ width: 20 }}>
                        <FontAwesomeIcon icon={faEnvelope} size="lg" />
                      </InputAdornment>
                    ),
                  }}
                sx={(props.theme==='light') ? {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.light_text
                      },
                    '& .MuiInputLabel-root': {
                        color: Colors.light_text
                     }, borderRadius: 3, marginTop: 0} 
                    :
                    {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.dark_text
                        },
                        '& .MuiInputLabel-root': {
                            color: Colors.dark_text_accent
                        }, borderRadius: 3, marginTop: 0}}
                value={urlEmail}
                onChange={(event)=>{setUrlEmail(event.target.value)}}
            />  
            <TextField 
                minRows={1}
                autoComplete="off" 
                className={props.theme+ '_accent _100perc margin_top_10px scale-up-center-9'}
                id="whatsapp"
                label={"WhatsApp"}
                size={'small'}
                InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" sx={{ width: 20 }}>
                        <FontAwesomeIcon icon={faWhatsapp} size="lg" />
                      </InputAdornment>
                    ),
                  }}
                sx={(props.theme==='light') ? {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.light_text
                      },
                    '& .MuiInputLabel-root': {
                        color: Colors.light_text
                     }, borderRadius: 3, marginTop: 0} 
                    :
                    {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.dark_text
                        },
                        '& .MuiInputLabel-root': {
                            color: Colors.dark_text_accent
                        }, borderRadius: 3, marginTop: 0}}
                value={urlWhatsapp}
                onChange={(event)=>{setUrlWhatsapp(event.target.value)}}
            />  
        </div>
    );
}

/*************************************************************************************
*
*                                   SMTP / MAIL
*
*************************************************************************************/
const seventhStep = () => {
    return (
        <div className="rowContainerEvenly" style={{width: '100%'}} >
            <div className='margin_top_10px slide-in-blurred-left' style={{width: '45%'}}>
                <h4>SMTP settings</h4>
                <hr/>
                <FormControl className={props.theme+ '_accent _100perc'}>
                  <InputLabel id="demo-simple-select-label">Preset</InputLabel>
                  <Select
                    className={props.theme+"_accent "+props.theme+"_text _100perc"}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={smtpPreset}
                    label="SMTP preset"
                    onChange={(event)=>{const {target: { value },} = event; setSmtpPreset(value); onSelectSmtpOption(value);}}
                    style={{height: 40, paddingTop: 8, paddingBottom: 8}}>
                        <MenuItem value={"-1"}>---</MenuItem>
                        {smtpOptions.map((row, index) => (
                            <MenuItem
                            key={index}
                            value={index}
                            >
                                {row.smtpid} - {row.smtpname}
                            </MenuItem>
                        ))}
                  </Select>
                </FormControl>
                <TextField
                    disabled={smtpPreset!=="-1"}
                    minRows={1}
                    autoComplete="off" 
                    className={props.theme+ '_accent _100perc margin_top_10px'}
                    id="smtp_name"
                    label={"SMTP name"}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={smtpName}
                    onChange={(event)=>{setSmtpName(event.target.value)}}
                    />  
                <TextField
                    disabled={smtpPreset!=="-1"}
                    minRows={1}
                    autoComplete="off" 
                    className={props.theme+ '_accent _100perc margin_top_10px'}
                    id="smtp_host"
                    label={"SMTP host"}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={smtpHost}
                    onChange={(event)=>{setSmtpHost(event.target.value)}}
                    required
                    />  
                <TextField
                    disabled={smtpPreset!=="-1"}
                    minRows={1}
                    autoComplete="off" 
                    className={props.theme+ '_accent _100perc margin_top_10px'}
                    id="smtp_user"
                    label={"SMTP username"}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={smtpUser}
                    onChange={(event)=>{setSmtpUser(event.target.value)}}
                    required
                    /> 
                <TextField
                    disabled={smtpPreset!=="-1"}
                    minRows={1}
                    autoComplete="off" 
                    className={props.theme+ '_accent _100perc margin_top_10px'}
                    id="smtp_pass"
                    label={"SMTP password"}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={smtpPass}
                    onChange={(event)=>{setSmtpPass(event.target.value)}}
                    required
                    />  
                <TextField
                    disabled={smtpPreset!=="-1"}
                    minRows={1}
                    autoComplete="off" 
                    className={props.theme+ '_accent _100perc margin_top_10px'}
                    id="smtp_port"
                    label={"SMTP port"}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={smtpPort}
                    onChange={(event)=>{setSmtpPort(event.target.value)}}
                    required
                    />  
                <div className='rowContainerBetween alignCenterInContainer'>
                    <div style={{marginLeft: 5}}>SSL</div>
                    <Switch
                      disabled={smtpPreset!=="-1"}
                      checked={smtpSSL}
                      onChange={(event)=>{setSmtpSSL(event.target.checked)}}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                </div>

                {smtpPreset==="-1" && <LoadingButton 
                    loading={validateSmtpLoader}
                    type="button" 
                    className='login__button _100perc'
                    size="large"
                    onClick={()=>{validateSmtp()}}>&nbsp;Test connection&nbsp;</LoadingButton>}
            </div>
            <div className='margin_top_10px slide-in-blurred-right' style={{width: '45%'}}>
                <h4>Mail settings</h4>
                <hr/>
                <TextField
                    minRows={1}
                    autoComplete="off" 
                    className={props.theme+ '_accent _100perc'}
                    id="mailFromName"
                    label={"Sender name"}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={mailFromName}
                    onChange={(event)=>{setMailFromName(event.target.value)}}
                    required
                    />  
                <TextField
                    minRows={1}
                    autoComplete="off" 
                    className={props.theme+ '_accent _100perc margin_top_10px'}
                    id="mailFromEmail"
                    label={"Sender email"}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={mailFromEmail}
                    onChange={(event)=>{setMailFromEmail(event.target.value)}}
                    required
                    />  
                <TextField
                    minRows={1}
                    autoComplete="off" 
                    className={props.theme+ '_accent _100perc margin_top_10px'}
                    id="mailSubject"
                    label={"Subject"}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={mailSubject}
                    onChange={(event)=>{setMailSubject(event.target.value)}}
                    required
                    />  
                <TextField
                    minRows={1}
                    autoComplete="off" 
                    className={props.theme+ '_accent _100perc margin_top_10px'}
                    id="mailError"
                    label={"Error email"}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={mailErrorEmail}
                    onChange={(event)=>{setMailErrorEmail(event.target.value)}}
                    />  
                <TextField
                    minRows={1}
                    autoComplete="off" 
                    className={props.theme+ '_accent _100perc margin_top_10px'}
                    id="mailbcc"
                    label={"BCC (general)"}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={mailBccEmail}
                    onChange={(event)=>{setMailBccEmail(event.target.value)}}
                    />  
                <TextField
                    minRows={1}
                    autoComplete="off" 
                    className={props.theme+ '_accent _100perc margin_top_10px'}
                    id="mailbccreceipt"
                    label={"BCC (receipt)"}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={mailBccReceipt}
                    onChange={(event)=>{setMailBccReceipt(event.target.value)}}
                    />  
                <TextField
                    minRows={1}
                    autoComplete="off" 
                    className={props.theme+ '_accent _100perc margin_top_10px'}
                    id="mailbccreservation"
                    label={"BCC (reservation)"}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={mailBccReservation}
                    onChange={(event)=>{setMailBccReservation(event.target.value)}}
                    />  
                <TextField
                    minRows={1}
                    autoComplete="off" 
                    className={props.theme+ '_accent _100perc margin_top_10px'}
                    id="mailbcccustorder"
                    label={"BCC (customer order)"}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={mailBccCustOrder}
                    onChange={(event)=>{setMailBccCustOrder(event.target.value)}}
                    />  
                <TextField
                    minRows={1}
                    autoComplete="off" 
                    className={props.theme+ '_accent _100perc margin_top_10px'}
                    id="mailbccoffer"
                    label={"BCC (offer)"}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={mailBccOffer}
                    onChange={(event)=>{setMailBccOffer(event.target.value)}}
                    />  
            </div>
        </div>
    );
}

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const saveHandler = () => {
        if(smtpHost !== "" && typeof smtpHost !== "undefined" && 
           smtpPass !== "" && typeof smtpPass !== "undefined" && 
           smtpUser !== "" && typeof smtpUser !== "undefined" && 
           smtpPort !== "" && typeof smtpPort !== "undefined" && 
           mailFromEmail !== "" && typeof mailFromEmail !== "undefined" && 
           mailFromName !== "" && typeof mailFromName !== "undefined" && 
           mailSubject !== "" &&  typeof mailSubject !== "undefined" && 
           color !== "" && typeof color !== "undefined" && 
           textColor !== "" && typeof textColor !== "undefined" && 
           discountColor !== "" && typeof discountColor !== "undefined" && 
           discountTextColor !== "" && typeof discountTextColor !== "undefined"){
            saveConfirmation();
        } else {
            props.notify(props.t('missing_fields'), 7500, "top-right", props.theme, 1); 
        }
        //console.log("port", smtpPort);
        //console.log("SAVE TIME");
        
    }


    const saveConfirmation = () => {
        if (window.confirm("Are you sure you would like to save the changes") === true) {
            setDisableControls(true);
            exportHtml(true);
        }
    }

    const applyTemplateHandler = () => {
        setApplyTemplateLoading(true);
        let url = SftGlobalData.baseURL_API + 'templates&action=fetchTemplate';
        let postData = {
            sft_id: props.login.sft_status,
            sft_group: props.login.sft_group,
            login: props.login.login,
            key: props.api,
            one_lang: true,
            id: applyTemplate
        }
        axios.post(url, postData, {
        headers : {
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
        }})
        .then(resp => {
            if(SftGlobalData.debug){
                console.log('url', url);
                console.log('postData', postData);
                console.log('response', resp);
            }
            setDesign(JSON.parse(resp.data.design));
            setHtml(resp.data.html);
            try{
                if(resp.data.design){
                    emailEditor.current.loadDesign(JSON.parse(resp.data.design));
                }
            }catch(e){}
            setApplyTemplateLoading(false);
        });
        
    }

    const validateSmtp = () => {
        setValidateSmtpLoader(true);
        let url = SftGlobalData.baseURL_API + 'eticket&action=validateSmtp';
        let postData = {
            sft_id: props.login.sft_status,
            sft_group: props.login.sft_group,
            login: props.login.login,
            key: props.api,
            host: smtpHost,
            user: smtpUser,
            port: smtpPort,
            pass: smtpPass,
            ssl: smtpSSL
        }
        axios.post(url, postData, {
        headers : {
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
        }})
        .then(resp => {
            if(SftGlobalData.debug){
                console.log('url', url);
                console.log('postData', postData);
                console.log('response', resp);
            }
            props.notify(resp.data.message, 2000, "bottom-center", props.theme, 5456886); 
            setValidateSmtpLoader(false);
        });
    }

    return (
        <div className={(props.screenWidth) < 600 ? 'not_expanded container_content _80perc ' + props.theme + ' container_content_' + props.theme : 'expanded container_content _80perc ' + props.theme + ' container_content_' + props.theme}>
            <FloatingSaveButton disabled={disableControls} onClick={()=>{saveHandler();}} />
            <FloatingBackButton disabled={disableControls} onClick={()=>{navigate("/"+props.lang+"/eticket");}} />
            {/*<FloatingMailButton disabled={disableControls} onClick={()=>{testMailHandler();}} />*/}
            <div className={"container_list__title " + props.theme}><h2>{props.t('eticket')} {(name !== "") && <span> - {name}</span>}</h2></div>
            {loading && <div className={"container_list__title " + props.theme} style={{display:"flex", justifyContent: "space-evenly", marginTop: 5, border: 1}}>
                <TailSpin 
                    height="40"
                    width="40"
                    color={Colors.sft_blue}
                    ariaLabel='loading'
                />
            </div>}
            <div className='rowContainerCenter _100perc'>
                {(!loading  && <Box className={props.theme + ' licence__enivronment'}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className={props.theme + ' container_tabs_below_subtitle'}>
                      <Tabs value={value} onChange={handleChange} className={props.theme} variant="scrollable" scrollButtons="auto">
                        <Tab label={"Data "} {...a11yProps(0)} className={props.theme}/>
                        <Tab label={"Logo"} {...a11yProps(1)} className={props.theme}/>                
                        <Tab label={"SMTP & mail "} {...a11yProps(2)} className={props.theme}/>
                        <Tab label={"Template "} {...a11yProps(3)} className={props.theme}/>                
                      </Tabs>
                  </Box>
                  
                  <TabPanel value={value} index={0}>
                        <div>
                            {secondStep()}
                            <hr/>
                            <h4>Detail</h4>
                            <br/>
                            {thirdStep()}
                            <hr/>
                            <h4>Disclaimer</h4>
                            <br/>
                            {fourthStep()}
                            <hr/>
                            <h4>URL</h4>
                            <br/>
                            {sixthStep()}
                            <br/>
                        </div>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                        <div>
                            {fifthStep()}
                        </div>
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                        <div>
                            {seventhStep()}
                            <br/>
                        </div>
                  </TabPanel>
                  <TabPanel value={value} index={3}>
                        <div>
                            <br/>
                            {firstStep()}
                            <hr/>
                            {password !== SftGlobalData.superUltraSecretLockPassword && <div>
                            <div className='columnContainerCenter alignCenterInContainer'>
                                <br/>
                                <FontAwesomeIcon icon={faLock} className='heartbeat' style={{color: "#dd0e0e",}} size={"7x"} />
                                <br/>
                            </div>
                            <div className='rowContainerCenter'>
                                <FormControl sx={{ m: 1, width: '25%', margin: '0' }}>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={showPassword ? 'text' : 'password'}
                                        name="txt_password" 
                                        className={props.theme+"_accent"}
                                        value={password}
                                        onChange={(event)=>{setPassword(event.target.value); checkPw(event.target.value);}}
                                        placeholder={props.t('password')}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                                >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                                
                            </div></div>}
                            {password === SftGlobalData.superUltraSecretLockPassword &&
                                <div className='columnContainerStart'>
                                    <div className='rowContainerBetween _50perc'>
                                    <FormControl className={props.theme+ '_accent _100perc margin_right__10px'}>
                                      <InputLabel id="demo-simple-select-label">Template</InputLabel>
                                      <Select
                                        className={props.theme+"_accent "+props.theme+"_text _100perc"}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={applyTemplate}
                                        label="Template"
                                        onChange={(event)=>{const {target: { value },} = event; setApplyTemplate(value);}}
                                        style={{height: 40, paddingTop: 8, paddingBottom: 8}}>
                                        {templates.map((template) => (
                                            <MenuItem
                                            key={template.id}
                                            value={template.id}
                                            >
                                                {template.title}
                                            </MenuItem>
                                        ))}
                                        
                                      </Select>
                                    </FormControl>
                                    <LoadingButton 
                                        loading={applyTemplateLoading}
                                        type="button" 
                                        className='login__button width_140px'
                                        size="large"
                                        onClick={()=>{applyTemplateHandler()}}>&nbsp;{props.t('apply')}&nbsp;</LoadingButton>
                                    </div>
                                    <br/>
                                    <div className='rowContainerCenter'>
                                    <EmailEditor
                                        minHeight={700}
                                        ref={emailEditor}
                                        onLoad={onLoad}
                                        onReady={onReady}
                                        appearance={
                                            {
                                                theme: props.theme,
                                                panels: {
                                                  tools: {
                                                    dock: 'right'
                                                  }
                                                }
                                            }
                                        }
                                        options={{
                                            displayMode: "mail", 
                                           
                                        }}
                                    />
                                    </div>
                                </div>}
                            <br/>
                        </div>
                  </TabPanel>
                  
              </Box>)}
          </div>
        </div>
    );
}

export default EticketDetailScreen;
