import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrash, faCopy, faEnvelope, faPersonDigging } from '@fortawesome/free-solid-svg-icons';
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';
import Button from '@mui/material/Button';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import ModalUnstyled from '@mui/base/ModalUnstyled';
import OutlinedInput from '@mui/material/OutlinedInput';
import Chip from '@mui/material/Chip';
import Checkbox from '@mui/material/Checkbox';

import SftGlobalData from '../../library/SftGlobalData';
import Colors from '../../library/Colors';
import './eticket.css';
import { ListItemText, FormControl, InputLabel, MenuItem, Select, Switch, TextField, useTheme } from '@mui/material';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
  
function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
}

function LinkCell (props) {
    return (<Link to={"/"+props.lang+"/eticket/"+props.id+"/"+props.element} style={{ width: '100%', height: '100%', alignContent: 'center', alignItems: 'center', display: 'flex'}}>{props.children}</Link>)
}

const StyledModal = styled(ModalUnstyled)`
    position: fixed;
    text-align: center;
    z-index: 1300;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    `;

const Backdrop = styled('div')`
    z-index: -1;
    position: fixed;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-tap-highlight-color: transparent;
    `;

const style = {
    bgcolor: 'background.paper',
    border: '0px solid #000',
    borderRadius: 6,
    p: 2,
    px: 4,
    pb: 3,
    };

const EticketScreen = (props) =>  {  
    const [stores, setStores] = useState([]);
    const [pos, setPos] = useState ([]);
    const [selectedStore, setSelectedStore] = useState([]);
    const [selectedPos, setSelectedPos] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState("");
    const [modalId, setModalId] = useState("0");
    const [modalElement, setModalElement] = useState({});
    const [loading, setLoading] = useState(true);
    const [active, setActive] = useState(false);
    const [attachments, setAttachments] = useState(false);
    const [lists, setLists] = useState([]);
    const [mainId, setMainId] = useState("0");
    const [dbVersion, setDbVersion] = useState(0);
    const [testEmail, setTestEmail] = useState(props.login.login);
    const [testTicketType, setTestTicketType] = useState("RECEIPT");
    const [testTicket, setTestTicket] = useState("");
    const [testData, setTestData] = useState({receipt: "", reservation: "", offer: "", cust_order: "", client_id: "", db_id: ""});
    const initialGrid = getCookie("initialStateEticket") === "{}" ? {columns: {columnVisibilityModel: {},}} : JSON.parse(getCookie("initialStateEticket"));
    const gridColumns = [
        {
            field: 'name',
            headerName: props.t('name'),
            minWidth: 300,
            headerAlign: 'left',
            align: 'left',        
            renderCell: (params) => {
                    return <LinkCell element={params.row.key} id={params.row.mainId} lang={params.row.lang} theme={params.row.theme} ><span>{params.row.name}</span></LinkCell>
                }
        },
        {
            field: 'store',
            headerName: props.t('store'),
            minWidth: 50,
            maxWidth: 100,  
            headerAlign: 'left',
            align: 'left',
            renderCell: (params) => {
                    return <LinkCell element={params.row.key} id={params.row.mainId} lang={params.row.lang} theme={params.row.theme} ><span>{params.row.store_id}</span></LinkCell>
                }
        },
        {
            field: 'POS',
            headerName: props.t('pos_id'),
            minWidth: 50,    
            maxWidth: 100,  
            align: 'left',      
            headerAlign: 'left', 
            renderCell: (params) => {
                    return <LinkCell element={params.row.key} id={params.row.mainId} lang={params.row.lang} theme={params.row.theme} ><span>{params.row.pos_id}</span></LinkCell>
                }
        },
        {
            field: 'edit',
            headerName: props.t('edit'),
            minWidth: 60,    
            maxWidth: 60,  
            align: 'center',      
            headerAlign: 'center', 
            renderCell: (params) => {
            return  <Button style={{color: (props.theme === 'dark' ? Colors.dark_text : Colors.light_text), height:"100%", borderRadius: "50%" }} onClick={()=>{navigate("/"+props.lang+"/eticket/"+params.row.mainId+"/"+params.row.key)}} >
                        <FontAwesomeIcon icon={faPencilAlt} color={Colors.def_orange} size="2x" />
                    </Button>;        
            }
        },
        {
            field: 'copy',
            headerName: props.t('copy'),
            minWidth: 60,    
            maxWidth: 100,  
            align: 'center',      
            headerAlign: 'center', 
            renderCell: (params) => {
              return  <Button style={{color: (props.theme === 'dark' ? Colors.dark_text : Colors.light_text), height:"100%", borderRadius: "50%" }} onClick={()=>{onDuplicate(params.row.mainId, params.row.key)}} ><FontAwesomeIcon icon={faCopy} color={Colors.def_grey} size="2x" /></Button>
              }
        },
        {
            field: 'delete',
            headerName: props.t('delete'),
            minWidth: 60,    
            maxWidth: 100,  
            align: 'center',      
            headerAlign: 'center', 
            renderCell: (params) => {
              return  ((params.row.pos_id !== "0000" || params.row.store_id !== "00" || ((props.login.sft_group === "ADMIN" || props.login.sft_group === "SUPER") && params.row.listSize === 1))) ? <Button style={{color: (props.theme === 'dark' ? Colors.dark_text : Colors.light_text), height:"100%", borderRadius: "50%" }} onClick={()=>{onDelete(params.row.mainId, params.row.key)}} >
                          <FontAwesomeIcon icon={faTrash} color={Colors.def_red} size="2x" />
                      </Button> : <div></div>;        
              }
        },
        {
            field: 'test',
            headerName: "Test",
            minWidth: 60,    
            maxWidth: 60,  
            align: 'center',      
            headerAlign: 'center', 
            renderCell: (params) => {
              return  <Button style={{color: (props.theme === 'dark' ? Colors.dark_text : Colors.light_text), height:"100%", borderRadius: "50%" }} onClick={()=>{testMail(params.row.mainId, params.row)}} ><FontAwesomeIcon icon={faEnvelope} color={Colors.def_grey} size="2x" /></Button>
              }
      },
      ];
    let navigate = useNavigate();
    const theme = useTheme();

    const onDuplicate = (id, element) => {
        setModalTitle("Duplicate target");
        setModalId(id);
        setModalElement(element);
        setModalContent("duplicate");
        setModalOpen(true);
    }

    const duplicateCall = (id, element) => {
        setModalOpen(false);
        let target = generateTargetJson();
        if(SftGlobalData.debug){
            console.log("stores", selectedStore);
            console.log("pos", selectedPos);
            console.log("target", target)
        }

        setLoading(true);
        let url = SftGlobalData.baseURL_API + 'eticket&action=duplicate';
        let postData = {
            sft_id: props.login.sft_id,
            sft_group: props.login.sft_group,
            login: props.login.login,
            id: id,
            key: props.api,
            element: element,
            stores: target.stores,
            pos: target.pos
        }

        axios.post(url, postData, 
            {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }
            }).then(response => {
                if(SftGlobalData.debug){
                    console.log('url', url);
                    console.log('postData', postData);
                    console.log('response', response);
                }            
                fetchHeaders();
                setSelectedStore([]);
                setSelectedPos([]);
        });
    }

    const generateTargetJson = () => {
        let storesData = ""; 
        selectedStore.forEach(element => {
            if(storesData !== ""){
                storesData += ",";
            }
            storesData += element.substring(0,2);
        });

        let posData = ""; 
        selectedPos.forEach(element => {
            if(posData !== ""){
                posData += ",";
            }
            posData += element.substring(0,4);
        });

        return {stores: storesData, pos: posData};
    }
   
    const onDelete = (id, element) => {
        if (window.confirm(props.t('confirm_delete_document')) === true) {
            setLoading(true);
            let url = SftGlobalData.baseURL_API + 'eticket&action=delete';
            let postData = {
                sft_id: props.login.sft_id,
                sft_group: props.login.sft_group,
                login: props.login.login,
                id: id,
                element: element,
                key: props.api
            }
    
            axios.post(url, postData, 
                {
                    headers : {
                        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                    }
                }).then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('postData', postData);
                        console.log('response', response);
                    }            
                    fetchHeaders();
            });
        }
    }
    
    
        const apiRef = useGridApiRef();
        
        
        useEffect(()=>{
            //console.log("adding subscriptions");
            try{
                apiRef.current.subscribeEvent(
                    'columnOrderChange',
                    ()=>{visibilityChangedHandler();},
                );
            }catch(ex){
        
            }
        }, [apiRef]);


        function setCookie(cname, cvalue, exdays) {
            const d = new Date();
            d.setTime(d.getTime() + (exdays*24*60*60*1000));
            let expires = "expires="+ d.toUTCString();
            //console.log("cookie", cname + "=" + cvalue + ";" + expires + ";path=/");
            document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        }
    
        function getCookie(cname) {
            let name = cname + "=";
            let decodedCookie = decodeURIComponent(document.cookie);
            let ca = decodedCookie.split(';');
            for(let i = 0; i <ca.length; i++) {
              let c = ca[i];
              while (c.charAt(0) === ' ') {
                c = c.substring(1);
              }
              if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
              }
            }
            return "{}";
        }
    
        const visibilityChangedHandler = () => {
            let temp = apiRef.current.exportState();
            if(typeof temp.columns.columnVisibilityModel === "undefined"){
                temp.columns.columnVisibilityModel = {};
            }
            try{
                temp.preferencePanel.open = false;
            }catch(e){
        
            }
            try{
                delete temp.pagination;
            }catch(e){
                console.error(e);
            }
            setCookie("initialStateEticket", JSON.stringify(temp), 100000);
        }

    useEffect(() => {
        props.changeScreen('Eticket');
        fetchDbVersion();
        fetchHeaders();
        fetchStores();
     },[props.api]);

    const fetchStores = () => {
        let url = SftGlobalData.baseURL_API + 'sftData';
        axios.post(url, {key: props.api, type: "stores"}, {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            }})
        .then(response => {
            if(SftGlobalData.debug){
                console.log('url', url);
                console.log('response', response);
            }
            setStores(response.data.data);
        });
        url = SftGlobalData.baseURL_API + 'sftData';
        axios.post(url, {key: props.api, type: "pos"}, {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            }})
        .then(response => {
            if(SftGlobalData.debug){
                console.log('url', url);
                console.log('response', response);
            }
            setPos(response.data.data);
        });
    }

    const fetchDbVersion = () => {
        let url = SftGlobalData.baseURL_API + 'sftData';
        axios.post(url, {key: props.api, type: "dbversion"}, {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            }})
        .then(response => {
            if(SftGlobalData.debug){
                console.log('url', url);
                console.log('response', response);
            }
            setDbVersion(parseInt(response.data.data));
        });
    }

    const fetchHeaders = () => {
        let url = SftGlobalData.baseURL_API + 'eticket&action=fetch';
        let postData = {
            sft_id: props.login.sft_id,
            sft_group: props.login.sft_group,
            login: props.login.login,
            key: props.api
        }
        axios.post(url, postData, {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            }})
            .then(response => {
                if(SftGlobalData.debug){
                    console.log('url', url);
                    console.log('postData', postData);
                    console.log('response', response);
                }
                //if((props.login.sft_group === "ADMIN" || props.login.sft_group === "SUPER")){
                    if(response.data.settings.length === 0 )
                        navigate("/"+props.lang+"/eticket-wizard");
                    else {
                        setMainId(response.data.id);
                        setLists(response.data.settings);
                        setActive(response.data.active);
                        setAttachments(response.data.enable_attachments);
                        setLoading(false);
                    }
                /*} else {
                    setLoading(false);
                }*/
                fetchTestData();
                
                
            });
    }

    const fetchTestData = () => {
        let url = SftGlobalData.baseURL_API + 'eticket&action=testData';
        let postData = {
            sft_id: props.login.sft_id,
            sft_group: props.login.sft_group,
            login: props.login.login,
            key: props.api
        }
        axios.post(url, postData, {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            }})
            .then(response => {
                if(SftGlobalData.debug){
                    console.log('url', url);
                    console.log('postData', postData);
                    console.log('response', response);
                }
                setTestData(response.data);
                setTestTicket(response.data?.receipt);
                //console.log("test id", response.data?.receipt);
            });
    }

    const changeTestType = (value) => {
        console.log("value", value);
        try{
            switch(value){
                case "RECEIPT": 
                    setTestTicket(testData?.receipt);
                    break;
                case "CUSTOMER_ORDER": 
                    setTestTicket(testData?.cust_order);
                    break;
                case "RESERVATION": 
                    setTestTicket(testData?.reservation);
                    break;
                case "OFFER": 
                    setTestTicket(testData?.offer);
                    break;
                default:
                    break;
            }
        }catch(e){
            console.error(e);
        }
    }

    const update = (activ, attachm) => {
        let url = SftGlobalData.baseURL_API + 'eticket&action=update';
        let postData = {
            sft_id: props.login.sft_id,
            sft_group: props.login.sft_group,
            login: props.login.login,
            key: props.api,
            id: mainId,
            active: activ,
            enable_attachments: attachm
        }
        axios.post(url, postData, {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            }})
            .then(response => {
                if(SftGlobalData.debug){
                    console.log('url', url);
                    console.log('postData', postData);
                    console.log('response', response);
                }
            });
    }

    const testMail = (id, element) => {
        
        setModalTitle("Send test email");
        setModalId(id);
        setModalElement(element);
        setModalContent("testmail");
        setModalOpen(true);
    }

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedStore(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const handleChangePos = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedPos(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const getModalContent = () => {
        switch(modalContent){
            case "duplicate":
                return  <div>
                <br/>
                <p>What is the target of the duplication?</p>
                <br/>
                <div className="rowContainerBetween" >
                    <FormControl sx={{ width: '100%', marginTop: 1, marginBottom: 3, marginRight: 1 }}>
                        <InputLabel id="demo-multiple-chip-label" className={props.theme+"_accent "+props.theme+"_text border_radius_top"}>Store</InputLabel>
                        <Select
                        style={{marginBottom:10}}
                        className={props.theme+"_accent "+props.theme+"_text"}
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        value={selectedStore}
                        onChange={handleChange}
                        input={<OutlinedInput id="select-multiple-chip" label="Store" />}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                                <Chip key={value} label={value} className={props.theme+"_accent_darker "+props.theme+"_text"}/>
                            ))}
                            </Box>
                        )}
                        MenuProps={MenuProps}
                        >
                        {stores.map((store) => (
                            <MenuItem
                            key={store.code}
                            value={store.code+" - "+store.oms}
                            style={getStyles(store.oms, selectedStore, theme)}
                            >
                                <Checkbox checked={selectedStore.indexOf(store.code+" - "+store.oms) > -1} />
                                <ListItemText primary={store.code+" - "+store.oms}/>
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>

                    <FormControl sx={{ width: '100%', marginTop: 1, marginBottom: 3 }}>
                        <InputLabel id="demo-multiple-chip-label-pos" className={props.theme+"_accent "+props.theme+"_text border_radius_top"}>POS</InputLabel>
                        <Select
                        className={props.theme+"_accent "+props.theme+"_text"}
                        labelId="demo-multiple-chip-label-pos"
                        id="demo-multiple-chip-pos"
                        multiple
                        value={selectedPos}
                        onChange={handleChangePos}
                        input={<OutlinedInput id="select-multiple-chip-pos" label="POS" />}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                                <Chip key={value} label={value} className={props.theme+"_accent_darker "+props.theme+"_text"}/>
                            ))}
                            </Box>
                        )}
                        MenuProps={MenuProps}
                        >
                        {pos.map((pos) => (
                            <MenuItem
                            key={pos.code}
                            value={pos.code+" - "+pos.oms}
                            style={getStyles(pos.oms, selectedPos, theme)}
                            >
                                <Checkbox checked={selectedPos.indexOf(pos.code+" - "+pos.oms) > -1} />
                                <ListItemText primary={pos.code+" - "+pos.oms}/>
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                </div>
                <div className="rowContainerBetween" style={{alignItems: 'center'}}>
                    {/*<Button type="button" className={"button_container__button_no_margin width_120px maxHeight "} onClick={()=>{duplicateCall(modalId, modalElement, 'stores')}}>&nbsp;{props.t('store')}&nbsp;</Button>
                    <Button type="button" className={"button_container__button_no_margin width_120px maxHeight "} onClick={()=>{duplicateCall(modalId, modalElement, 'pos')}}>&nbsp;{props.t('pos_id')}&nbsp;</Button>*/}
                    <Button type="button" className={"button_container__button_no_margin width_120px maxHeight "} onClick={()=>{setModalOpen(false);}}>&nbsp;{props.t('back')}&nbsp;</Button>
                    <Button type="button" className={"button_container__button_no_margin width_120px maxHeight "} onClick={()=>{duplicateCall(modalId, modalElement)}}>&nbsp;{props.t('copy')}&nbsp;</Button>
                </div>
            </div>
            case "testmail":
                return <div className="" style={{width: '100%', textAlign: 'left'}} >
                <div className="rowContainerBetween alignCenterInContainer margin_bottom_10px">
                    <FormControl className={props.theme+ '_accent _100perc margin_right__10px'}>
                      <InputLabel id="demo-simple-select-label">Transaction type</InputLabel>
                      <Select
                        className={props.theme+"_accent "+props.theme+"_text _100perc"}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={testTicketType}
                        label="Transaction type"
                        onChange={(event)=>{const {target: { value },} = event; setTestTicketType(value); changeTestType(value);}}
                        style={{height: 40, paddingTop: 8, paddingBottom: 8}}>
                            <MenuItem value={"CUSTOMER_ORDER"}>Customer order</MenuItem>
                            {/*<MenuItem value={"OFFER"}>Offer</MenuItem>*/}
                            <MenuItem value={"RECEIPT"}>Receipt</MenuItem>
                            <MenuItem value={"RESERVATION"}>Reservation</MenuItem>
                      </Select>
                    </FormControl>
                    <TextField
                        minRows={1}
                        autoComplete="off" 
                        className={props.theme+ '_accent _100perc'}
                        required
                        id="testTicket"
                        label={"Transaction ID"}
                        size={'small'}
                        sx={(props.theme==='light') ? {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.light_text
                              },
                            '& .MuiInputLabel-root': {
                                color: Colors.light_text
                             }, borderRadius: 3, marginTop: 0} 
                            :
                            {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.dark_text
                                },
                                '& .MuiInputLabel-root': {
                                    color: Colors.dark_text_accent
                                }, borderRadius: 3, marginTop: 0}}
                        value={testTicket}
                        onChange={(event)=>{setTestTicket(event.target.value)}}
                        />  
                </div>

                <div className="rowContainerBetween margin_bottom_10px" style={{alignItems: 'center'}}>
                    <TextField
                        minRows={1}
                        autoComplete="off" 
                        className={props.theme+ '_accent _100perc'}
                        required
                        id="testTicketMail"
                        label={"Recipient email"}
                        size={'small'}
                        sx={(props.theme==='light') ? {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.light_text
                              },
                            '& .MuiInputLabel-root': {
                                color: Colors.light_text
                             }, borderRadius: 3, marginTop: 0} 
                            :
                            {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.dark_text
                                },
                                '& .MuiInputLabel-root': {
                                    color: Colors.dark_text_accent
                                }, borderRadius: 3, marginTop: 0}}
                        value={testEmail}
                        onChange={(event)=>{setTestEmail(event.target.value)}}
                        />  
                        <Button type="button" className={"button_container__button_no_margin width_120px maxHeight "} onClick={()=>{testMailCall(modalElement)}}>&nbsp;{props.t('send')}&nbsp;</Button>
                </div>

                <Button type="button" className={"button_container__button_no_margin _100perc maxHeight "} onClick={()=>{setModalOpen(false);}}>&nbsp;{props.t('back')}&nbsp;</Button>
            </div>
            default:
                return <div></div>
        }
    }


    const testMailCall = () => {
        
        if(testEmail !== "" && testTicket !== ""){
            setModalOpen(false);
            let url = SftGlobalData.baseURL_node + 'debug-call';
            let postData = {
                client_id: testData.client_id,
                db_id: testData.db_id,
                store_id: modalElement.store_id,
                pos_id: modalElement.pos_id,
                transaction_type: testTicketType,
                transaction_id: testTicket,
                source: "MYSFT",
                is_test: true,
                recipient: testEmail
                };
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/json; charset=UTF-8'
                }})
            .then(response => {
                if(SftGlobalData.debug){
                    console.log('url', url);
                    console.log('PostData', postData);
                    console.log('response', response);
                }
                props.notify(response.data?.message, 7500, "top-right", props.theme, 1); 
            });
        }

        
    }

    return (
        <div className={(props.screenWidth) < 600 ? 'not_expanded container_content _80perc ' + props.theme + ' container_content_' + props.theme : 'expanded container_content _80perc ' + props.theme + ' container_content_' + props.theme}>
            <StyledModal
                aria-labelledby="unstyled-modal-title"
                aria-describedby="unstyled-modal-description"
                open={modalOpen}
                onClose={()=>{setModalOpen(false);}}
                BackdropComponent={Backdrop}
                >
                <Box sx={style} style={{minWidth: 500}} className={props.theme + "_forced _50perc"}>
                    <p className={"primary_border_bottom text_center margin_top_none margin_bottom_15px bold "} style={{fontWeight: 1200}}>{modalTitle}</p>
                    <div>
                        {modalContent !== "" && getModalContent()}
                    </div>
                </Box>
            </StyledModal>
            
            <div className={"container_list__title " + props.theme}>
                <h2>{props.t('eticket')}</h2>
                {(dbVersion >= 314) && <Switch
                  checked={active}
                  onChange={()=>{setActive(!active); update(!active, attachments);}}
                  inputProps={{ 'aria-label': 'controlled' }}
                />}
                {(dbVersion < 314) && <p className='error_text'>Database update required.	&#40;current: {dbVersion}, required: 314&#41;</p>}
            </div>
           <div className='rowContainerCenter _100perc'>
                <DataGridPro
                  apiRef={apiRef}
                  initialState={initialGrid}
                  className={'manuals_application_headers ' + props.theme}
                  columns={gridColumns}
                  rows={lists.map((row, index) => {return {
                          id: row.key,
                          mainId: mainId,
                          key: row.key,
                          lang: props.lang,
                          theme: props.theme,
                          name: row.name,
                          store_id: row.store_id,
                          pos_id: row.pos_id,
                          edit: "Edit",
                          delete: "Delete",
                          copy: "Copy",
                          active: row.active,
                          listSize: lists.length
                      }                          
                  })} 
                  onColumnWidthChange={()=>{visibilityChangedHandler();}}
                  onColumnVisibilityModelChange={()=>{visibilityChangedHandler();}}
                  onPreferencePanelClose={()=>{visibilityChangedHandler();}}
                  rowThreshold={0}
                  rowHeight={38}
                  height={300}
                  loading={loading}
                  pagination={false}

                  /> 
            </div>
            {/*(props.login.sft_group !== "ADMIN" && props.login.sft_group !== "SUPER") && <div className='columnContainerCenter alignCenterInContainer'>
                <br/><br/>
                <FontAwesomeIcon icon={faPersonDigging} className='heartbeat' style={{color: "#dd0e0e",}} size={"7x"} />
                <br/>
                <p>This area is under construction and therefor <b>ADMIN ONLY.</b></p>
                <br/><br/>
            </div>*/}
        </div>

        
    );
}

export default EticketScreen;
