import React, { useEffect, useState } from 'react';
import './logging.css';
import SftGlobalData from '../../library/SftGlobalData';
import axios from 'axios';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import StoresTable from '../../components/logging/StoresTable';
import CustomersTable from '../../components/logging/CustomersTable';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        style={{width: '100%'}}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 2}}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
  
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const LoggingCustomersScreen = (props) =>  {
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState(0);

    const [initialGridCustomers, setInitialGridCustomers] = useState(getCookie("initialStateCustomers") === "{}" ? {columns: {columnVisibilityModel: {},}} : JSON.parse(getCookie("initialStateCustomers")));
    const [initialGridStores, setInitialGridStores] = useState(getCookie("initialStateStores") === "{}" ? {columns: {columnVisibilityModel: {name2: false, address2: false, tel2: false, fax: false, fax2: false,},}} : JSON.parse(getCookie("initialStateStores")));

    const [customers, setCustomers] = useState([]);
    const [stores, setStores] = useState([]);

    useEffect(()=>{
        if(!loading){
            setLoading(true);
            props.changeScreen('logging');
            loadCustomers();
            loadStores();
        }
    }, [props.loginObj]);

    const loadCustomers = () => {
        try{
            let url = SftGlobalData.baseURL_API+'login';
            let postData = {action: "logging", login: props.loginObj?.login, password: props.loginObj?.password}
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('postData', postData);
                        console.log('resp', response);
                    }
                    setCustomers(response.data);
                    setLoading(false);
                });
        } catch(e){
            console.error(e);
        }
    }

    const loadStores = () => {
        let url2 = SftGlobalData.baseURL_API + 'logging&action=fetchDeliveryAddress';
        let postData2 = {type: props.sftStatus, sft_id: 0, sft: props.loginObj?.sft_status};
        axios.post(url2, postData2, {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            }})
        .then(response => {
            if(SftGlobalData.debug){
                console.log('url', url2);
                console.log('postData', postData2);
                console.log('response', response);
            }
            setStores(response.data);
        });
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
        if(getCookie("initialStateCustomers") !== "{}"){
            let temp = JSON.parse(getCookie("initialStateCustomers"));
            try{
                temp.preferencePanel.open = false;
            }catch(e){
    
            }
            setInitialGridCustomers(temp);
        }
        if(getCookie("initialStateStores") !== "{}"){
            let temp2 = JSON.parse(getCookie("initialStateStores"));
            try{
                temp2.preferencePanel.open = false;
            }catch(e){
    
            }
            setInitialGridStores(temp2);
        }
    };

    function getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) === ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "{}";
    }
    

    return (
        <div className={'_100perc log__main_container log__padding_sidebar'}>
            <div className={'log__container_content ' + props.theme + ' container_content_' + props.theme + ' box_fade-in'} >
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center'}}>
                    
                    {
                        // --------------------------------------------------------------------
                        // TITLE / HEADER
                        // --------------------------------------------------------------------
                    }
                    <div className={props.theme + " _100perc "}>
                        <div className="container_list__title ">
                            <h2 className={props.theme} type="text" id="logging" name="logging">{props.t('logging')} - {props.t('customers')}</h2>
                        </div>
                    </div>
                    {
                        // --------------------------------------------------------------------
                        // CONTENT
                        // --------------------------------------------------------------------
                    }
                    <Box className={props.theme + ' log__container_inner_content'}>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '99%'}} className={props.theme + ' container_tabs_below_subtitle  '}>
                          <Tabs value={value} onChange={handleChange} className={props.theme} variant="scrollable" scrollButtons="auto">
                            <Tab label={props.t('customers')} {...a11yProps(0)} className={props.theme}/>
                            <Tab label={props.t('stores')} {...a11yProps(1)} className={props.theme}/>                
                          </Tabs>
                      </Box>
                      {
                        // --------------------------------------------------------------------
                        // CUSTOMERS
                        // --------------------------------------------------------------------
                      }
                      <TabPanel value={value} index={0}>
                            <CustomersTable login={props.loginObj} lang={props.lang} loadCustomers={()=>{loadCustomers();}} userId={props.userId} initialGrid={initialGridCustomers} data={customers} loading={loading} t={props.t} theme={props.theme} />
                      </TabPanel>
                      {
                        // --------------------------------------------------------------------
                        // STORES
                        // --------------------------------------------------------------------
                      }
                      <TabPanel value={value} index={1}>
                        <StoresTable login={props.loginObj} loadStores={()=>{loadStores();}} userId={props.userId} initialGrid={initialGridStores} data={stores} loading={loading} t={props.t} theme={props.theme} />
                      </TabPanel>
                    </Box>
                </div>
            </div>
        </div>
    );
}

export default LoggingCustomersScreen;
