export default {
    baseURL_API: 'https://auth.softtouch.eu/login.php?route=',
    baseURL_API_old: 'https://shipping.softtouch.eu/bpost/getApi.php',
    baseURL_shopping: 'https://shopping.softtouch.eu/',
    baseURL_office: 'https://office.softtouch.eu/',
    baseURL_files: 'https://my.softtouch.eu/files/',
    baseURL_node: 'https://queuetest.softtouch.eu/',
    baseURL_auth: 'https://auth.softtouch.eu/',
    baseURL_SFT_API: 'https://api-sft.softtouch.eu/',
    debug: false,
    //baseURL_V2: 'https://api-sft.softtouch.eu/2/accounts/',
    version: 'V2.24.35.1',
    appName: 'MySoftTouch',
    website: 'www.softtouch.eu',
    email: 'support@softtouch.be',
    phone: '+32 (0)9 219 00 83',
    easter1: false,
    easter2: false,
    easter3: false,
    dateObjFormatter: (date) => {
        let localDate = date;
        const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
        return localDate.toLocaleString('fr-BE', options);
    },
    dateFormatter: (date) => {
        let localDate = new Date(date);
        const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
        return localDate.toLocaleString('fr-BE', options);
    },
    dateTimeFormatter: (dateTime) => {
        let localDateTime = new Date(dateTime);
        const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
        return localDateTime.toLocaleString('fr-BE', options);
    },
    escapeText: (string) => {
        const replacements = {
            "'": "\\'",
            '"': '\\"',
            '\\': '\\\\',
            '\n': '\\n',
            '\r': '\\r',
            '\t': '\\t',
            '\b': '\\b',
            '\f': '\\f',
        };
        return string.replace(/['"\\\n\r\t\b\f]/g, (char) => replacements[char]);
    },
    vlChecker: (sft) => {
        return (sft === "1" || sft === "2");
    },
    wallChecker: (sft) => {
        return (sft === "1" || sft === "3");
    },
    superUltraSecretLockPassword: "DitIsBertZijnWachtwoordVoor2024!"
}